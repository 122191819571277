import { createAsyncThunk } from "@reduxjs/toolkit"
import { Orders } from "../../entities/Order"

export const fetchOrdersTable = createAsyncThunk(
  'orders/fetchOrders',
  async () => {
    const ordersListRes = await fetch('https://test.b2c.by/api/v1/dashboard/orders/')

    if (ordersListRes.status !== 200) {
      return null
    }
    return (await ordersListRes.json()) as Promise<Orders>
  }
)