
import React, { ReactNode } from 'react';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

const TabsCus = withStyles({
    root: {
        borderBottom: '2px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#2475FB',
        height: '4px',
        borderRadius: '3px 3px 0px 0px'
    },
})(Tabs);

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'transparent',
        padding: "0px 10px",
    },
    label: {
        fontSize: '16px',
        textTransform: 'none',
        minWidth: '0',
        padding: '8px'
    }
}));

export default function TabInfo({ data }: { data: { name: string, component: ReactNode }[] }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);


    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>

            <TabsCus value={value} onChange={handleChange} aria-label="simple tabs example">
                {
                    data.map((item, index) => {
                        return (
                            <Tab key={index} className={classes.label} label={item.name} {...a11yProps(index)} />
                        )
                    })
                }
            </TabsCus>
            {
                data.map((item, index) => {
                    return (
                        <TabPanel key={index} value={value} index={index}>
                            {item.component}
                        </TabPanel>
                    )
                })
            }
        </div>
    );
}
