import React from "react"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"

interface TableColsProps {
  children: React.ReactNode,
  status?: string,
  scroll?: boolean
}
const useStyle = makeStyles({
  columnStyle: {
    borderRadius: 8,
    padding: '12px 4px 10px 4px',
    background: '#ffffff',

    overflowX: 'hidden',
    overflowY: 'auto',
    // maxHeight: '85vh', //Размер скролящегося окна (настроить по необходимости)
    scrollbarWidth: 'none',
    position: 'relative',
    '&::-webkit-scrollbar': {
      width: 0,
    }
  },
  scroll:{
    padding:'2px 4px 10px 4px',
  },
  onlyTable: {
    width: '100%',
    transition: 'all .3s'
  },
  twoInOne: {
    width: '50%',
    transition: 'all .3s'
  },
  onlyInfo: {
    display: 'none'
  }
})
export const TableCols = ({ children, status, scroll }: TableColsProps) => {
  const classes = useStyle()
  const className = React.useMemo(() => {
    if (status === 'onlyTable')
      return classes.onlyTable
    if (status === 'twoInOne')
      return classes.twoInOne
    if (status === 'onlyInfo')
      return classes.onlyInfo
  }, [status, classes])
  const classsNameScroll = React.useMemo(() => {
    if(scroll){
      return classes.scroll
    } 
    return ''
  }, [scroll])
  return <div className={clsx(className, classes.columnStyle)}>{children}</div>
}