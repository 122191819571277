export const DateView = ({ date }: { date: string }) => {
  const thisdate = new Date(date)
  const checkNumber = (text: Number) => {
    return text > 9 ? text : `0${text}`
  }
  const Month = (month: any) => {
    const arr: string[] = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек']
    return arr[month]
  }
  return (
    <>
      {`${checkNumber(thisdate.getDate())} ${Month(thisdate.getMonth())} ${thisdate.getFullYear()} ${checkNumber(thisdate.getHours())}:${checkNumber(thisdate.getMinutes())}`}
    </>
  )
}