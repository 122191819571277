import React from "react";
import { Header } from "../../components/TableRowInfo/Header";
import { Parameter, ParameterItem } from "../../components/TableRowInfo/Parameter";
import { NumberDigits } from "../../utils/NumberRender";
import { TableRowInfo } from "../../components/TableRowInfo";
import TabInfo from "../../components/TableRowInfo/TabInfo";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../store";
import { TabsServices } from "./TabServices";
import { TabsPerformer } from "./TabPerformers";
import { Box } from "@material-ui/core";
import { PerformersForCity, ServicesForCity } from "../../store/City/thunks";
import { RaitingStyle } from "../../utils/Raiting";
import { backItem, closeInfoTable, nextItem, openInfoPage } from "../../store/City/cityStore";

export const CitiesInfoBlock = ({ statusInfo }: { statusInfo: string }) => {
    const dispatch = useAppDispatch();
    const { activeItem, activeItemIndex } = useSelector((state: RootState) => state.city)
    const history = useHistory();

    const fetchCityInfo = React.useCallback(() => {
        if (activeItem) {
            dispatch(PerformersForCity(activeItem.id))
            dispatch(ServicesForCity(activeItem.id))
        }
    }, [dispatch, activeItem])
    React.useEffect(() => {
        fetchCityInfo()
    }, [fetchCityInfo])

    const openFullInfo = () => {
        if (activeItem) {
            dispatch(openInfoPage())
            history.push(`/cities/${activeItem.id}`);
        }
    }
    const closeInfo = () => {
        dispatch(closeInfoTable())
    }
    const nextInfo = () => {
        dispatch(nextItem())
    }
    const backInfo = () => {
        dispatch(backItem())
    }
    const tabInfo = [
        {
            name: 'Услуги',
            component: <Box><TabsServices /></Box>
        },
        {
            name: 'Исполнители',
            component: <Box><TabsPerformer /></Box>
        },
    ]

    const Parameters = () => {
        return (
            <Parameter >
                <ParameterItem params={[{ name: 'id', value: activeItem && activeItem.id ? activeItem.id : '' }]} />
                <ParameterItem params={[{ name: 'Город', value: activeItem && activeItem.title ? activeItem.title : '' }, { name: 'Область', value: activeItem && activeItem.region ? activeItem.region : '' }]} />
                <ParameterItem params={[{ name: 'Население', value: NumberDigits(activeItem && activeItem.population ? activeItem.population : 0) }, {
                    name: 'Исполнит.',
                    value: activeItem && activeItem.workersCount ? activeItem.workersCount : 0,
                    txtColor: RaitingStyle(activeItem && activeItem.workersCount ? activeItem.workersCount : 0, { minimum: 50, average: 70, maximum: 90 })
                }]} />
                <ParameterItem params={[{ name: 'Рейтинг', value: activeItem && activeItem.rating ? activeItem.rating : 0, txtColor: RaitingStyle(activeItem && activeItem.rating ? activeItem.rating : 0, { minimum: 50, average: 70, maximum: 90 }) }]} />
            </Parameter>
        )
    }
    const PageParameters = () => {
        return (
            <Parameter >
                <ParameterItem params={[
                    { name: 'id', value: activeItem && activeItem.id ? activeItem.id : '' },
                    { name: 'Город', value: activeItem && activeItem.title ? activeItem.title : '' },
                    { name: 'Область', value: activeItem && activeItem.region ? activeItem.region : '' }]} />
                <ParameterItem params={[
                    { name: 'Население', value: NumberDigits(activeItem && activeItem.population ? activeItem.population : 0) },
                    {
                        name: 'Исполнит.',
                        value: activeItem && activeItem.workersCount ? activeItem.workersCount : 0,
                        txtColor: RaitingStyle(activeItem && activeItem.workersCount ? activeItem.workersCount : 0, { minimum: 50, average: 70, maximum: 90 })
                    },
                    {
                        name: 'Рейтинг',
                        value: activeItem && activeItem.rating ? activeItem.rating : 0,
                        txtColor: RaitingStyle(activeItem && activeItem.rating ? activeItem.rating : 0, { minimum: 50, average: 70, maximum: 90 })
                    }
                ]} />
            </Parameter>
        )
    }

    return (
        <TableRowInfo>
            <Header
                title={activeItem && activeItem.title ? activeItem.title : ''}
                accordion={true}
                expanded={true}
                onOpen={openFullInfo}
                onClose={closeInfo}
                onNext={nextInfo}
                onBack={backInfo}
                status={statusInfo}
                indexItem={activeItemIndex}>
                {
                    statusInfo === 'onlyInfo' ?
                        <PageParameters />
                        :
                        <Parameters />
                }
            </Header>
            <TabInfo data={tabInfo} />
        </TableRowInfo>
    )
}