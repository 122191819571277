import React from 'react'
import { TwoColsPage } from '../../common/TwoColsPage'
import { TableCols } from '../../components/Table/TableCols'
import { PageDashboard } from '../../navigation/PageDashboard'
import { useSelector } from 'react-redux'
import { RootState } from "../../store"
import { InfoCols } from '../../common/InfoCols'
import { OrdersTable } from './OrdersTable'
import { selectOrder } from '../../store/table/tableStore'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'
import { fetchOrdersTable } from '../../store/table/orderLoad'

export default function Orders() {
    const dataOrdersFetch = useAppSelector(selectOrder)
    const dispatch = useAppDispatch()
    const status = useSelector((state: RootState) => state.table.Orders.status)

    const WW = React.useMemo(() => {
        return window.innerWidth
    }, [window.innerWidth])
    const [windowWidth, setWindowWidth] = React.useState(WW)
    React.useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.onload = handleResize

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize ', handleResize);
    }, [])

    React.useEffect(() => {
        if (!dataOrdersFetch?.results) {
            dispatch(fetchOrdersTable())
        }
    }, [dispatch, dataOrdersFetch?.results])
    
    return (
        <PageDashboard>
            <TwoColsPage>
                <TableCols status={status}>
                    <OrdersTable windowWidth={windowWidth} />
                </TableCols>
                <InfoCols status={status}>
                </InfoCols>
            </TwoColsPage>
        </PageDashboard>
    )
}