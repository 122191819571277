import { makeStyles } from "@material-ui/core"
import React from "react"

const TwoColsPageStyle = makeStyles({
  twoCols: {
    margin: '14px auto 0px auto ',
    padding: 0,
    display: "flex",
    width: '99%',
    position: 'relative'
  }
})
export const TwoColsPage = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={TwoColsPageStyle().twoCols}>
      {children}
    </div>
  )
}