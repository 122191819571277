import { Box, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

interface TableBoxProps {
  children: React.ReactNode,
  status?: string
}

const useStyle = makeStyles({
  columnStyle: {
    // borderRadius: 8,
    // padding: '12px 4px 10px 4px',
    // background: '#ffffff',

    overflowX: 'hidden',
    overflowY: 'auto',
    // maxHeight: '85vh', //Размер скролящегося окна (настроить по необходимости)
    scrollbarWidth: 'none',
    position: 'relative',
    '&::-webkit-scrollbar': {
      width: 0,
    }
  },
  onlyTable: {
    width: '100%',
    transition: 'all .3s'
  },
  twoInOne: {
    width: '50%',
    transition: 'all .3s'
  }
})

export const TableBox = ({ children, status }: TableBoxProps) => {
  const classes = useStyle()
  const className = React.useMemo(() => {
    if (status === 'onlyTable')
      return classes.onlyTable
    if (status === 'twoInOne')
      return classes.twoInOne
  }, [status, classes])
  return (
    <Box className={clsx(className,classes.columnStyle)}>
      {children}
    </Box>
  )

}