import React, { ComponentType } from "react";
import { TableBody, TableCellProps, TableHeadProps, TableProps, TableRowProps } from "@material-ui/core";
import clsx from "clsx";
import { Skeleton } from "@material-ui/lab";
import { SkeletTable } from "../Skeleton";

export interface ColumnSpec<T extends Object, TableCellComponentProps, TableHeadCellComponentProps> {
  colId: string,
  visible: boolean,
  tableHeadCellProps?: TableHeadCellComponentProps,
  tableCellProps?: Omit<TableCellComponentProps, 'item'>,
  HeadCellContentRender: React.FC<{ spec: ColumnSpec<T, TableCellComponentProps, TableHeadCellComponentProps> }>,
  CellContentRender: React.FC<{ item: T, index: number, spec: ColumnSpec<T, TableCellComponentProps, TableHeadCellComponentProps> }>,
}

export const DataTable = <
  T extends Object,
  TableComponentProps extends TableProps,
  TableRowComponentProps extends TableRowProps & { item: T },
  TableCellComponentProps extends TableCellProps & { item: T },

  TableHeadComponentProps extends TableHeadProps,
  TableHeadRowComponentProps extends TableRowProps,
  TableHeadCellComponentProps extends TableCellProps,
  >(props: {
    spec: ColumnSpec<T, TableCellComponentProps, TableHeadCellComponentProps>[],
    data: T[],
    itemKey: (item: T) => React.Key,

    Table: ComponentType<TableComponentProps>,
    tableProps?: TableComponentProps,

    tableRowProps?: Omit<TableRowComponentProps, 'item'>,
    TableRow: ComponentType<TableRowComponentProps>,

    TableCell: ComponentType<TableCellComponentProps>,

    tableHeadProps?: TableHeadComponentProps,
    TableHead: ComponentType<TableHeadComponentProps>,

    tableHeadRowProps?: TableHeadRowComponentProps,
    TableHeadRow: ComponentType<TableHeadRowComponentProps>,

    TableHeadCell: ComponentType<TableHeadCellComponentProps>,

    TableRowOnClick?: any,
    activeItem?: number,
    loading?: boolean,
    tableHeadRowStyle?: any
  }) => {
  const {
    Table,
    tableProps,
    TableRow,
    tableRowProps,
    TableCell,
    TableHead,
    tableHeadProps,
    TableHeadRow,
    tableHeadRowProps,
    TableHeadCell,
    spec,
    data,
    itemKey,
    TableRowOnClick,
    activeItem,
    loading,
    tableHeadRowStyle
  } = props;
  return (
    <Table {...tableProps as any}>
      <TableHead {...tableHeadProps as any}>
        <TableHeadRow {...tableHeadRowProps as any} className={tableHeadRowStyle}>
          {spec.filter(elem => elem.visible === true).map(colSpec => {
            const { HeadCellContentRender } = colSpec
            return (
              <TableHeadCell key={colSpec.colId} {...colSpec.tableHeadCellProps as any}>
                <HeadCellContentRender spec={colSpec} />
              </TableHeadCell>
            )
          })}
        </TableHeadRow>
      </TableHead>
      <TableBody>
        {data && !loading &&
          data.map((item: T, index) => {
            return (
              <TableRow onClick={TableRowOnClick} key={itemKey(item)} className={clsx(itemKey(item) === activeItem && 'active')} {...tableRowProps as any}>
                {spec.filter(elem => elem.visible === true).map(colSpec => {
                  const { CellContentRender } = colSpec
                  return (
                    <TableCell key={colSpec.colId} {...colSpec.tableCellProps as any}>
                      <CellContentRender item={item} index={index} spec={colSpec} />
                    </TableCell>
                  )
                })}
              </TableRow>
            );
          })}
        {
          loading &&
          <SkeletTable windowWidth={1000} tableRowProps={tableRowProps} colSpan={spec.length} />
        }
      </TableBody>
    </Table>
  );
}