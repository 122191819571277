import { TableRow, withStyles } from "@material-ui/core";

export const TableHeadRow = withStyles({
  root: {
    border: 'none',
    whiteSpace: 'nowrap',
    "& th": {
      position: 'relative',
      border: 'none',
      height: '22px',
      paddingBottom: '2px',
    },
    "& th:first-child span": {
      paddingLeft: 8
    },
    "& th:last-child": {
      paddingRight: '0px',
      paddingLeft: '8px'
    }
  }
})(TableRow);

export const TableHeadRowMobile = withStyles({
  root: {
    border: 'none',
    whiteSpace: 'nowrap',
    '@media screen and (max-width:1200px)': {
      display: "grid",
      gridTemplateRows: "repeat(4, 22px)",
    },

    "& th": {
      position: 'relative',
      border: 'none',
      height: '22px',
      paddingBottom: '2px',
      '& span': {
        '@media screen and (max-width:1200px)': {
          display: 'block'
        },
      }
    },
    "& th:first-child span": {
      paddingLeft: 8
    },
    "& th:last-child": {
      paddingRight: '0px',
      paddingLeft: '8px'
    }
  }
})(TableRow);

export const CommonTableHeadRow = TableHeadRow;

export const CommonTableHeadRowMobile = TableHeadRowMobile