import {
    makeStyles,
    Box,
    SvgIcon,
    Typography,
    Accordion,
    AccordionSummary,
    withStyles,
    AccordionDetails, IconButton
} from "@material-ui/core";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as FullscreenIcon } from "./../../../icons/icon-open-full-page.svg";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const AccordionSummaryCus = withStyles({
    root: {
        minHeight: 0,
        '& div': {
            margin: '0px',
            padding: '0px'
        },
        "& .Mui-expanded": {
            margin: '0px',
            minHeight: 0
        }
    }
})(AccordionSummary)

const IconButtonCus = withStyles({
    root: {
        padding: "2px"
    }
})(IconButton)

const AccordionCus = withStyles({
    root: {
        minHeight: 0,
        "& .Mui-expanded": {
            margin: '0px',
            minHeight: 0
        }
    }
})(Accordion)

const AccordionDetailsCus = withStyles({
    root: {
        padding: "8px 10px 0px",
        fontSize: "16px",
        display: "flex",
        flexWrap: "wrap"
    }
})(AccordionDetails)

const useStyles = makeStyles({
    svgRoot: {
        fill: 'transparent',
        marginRight: '4px'
    },
    icon: {
        color: "#3D2191",
        cursor: 'pointer'
    },
    accordion: {
        border: "none",
        boxShadow: "none",
        marginBottom: "10px",
    },
    title: {
        fontSize: "22px"
    },
    tagsSize: {
        fontSize: "16px"
    }
})

interface HeaderProps {
    children: React.ReactNode,
    title: string,
    accordion: boolean,
    expanded: boolean,
    onOpen?: () => void,
    onClose?: () => void,
    onBack?: () => void,
    onNext?: () => void,
    status?: string,
    indexItem?: number
}

export const Header = ({ children, title, accordion, expanded, onOpen, onClose, onBack, onNext, status, indexItem }: HeaderProps) => {
    const classes = useStyles();
    const [expandedState, setExpandedState] = useState(expanded);

    const activeItemIndex = indexItem
    const statusTable = React.useMemo(() => {
        return status
    }, [status])

    const backIsDisabled: boolean = React.useMemo(() => {
        return (activeItemIndex === 0);
    }, [activeItemIndex])

    const nextIsDisabled: boolean = React.useMemo(() => {
        return (activeItemIndex === 100)
    }, [activeItemIndex])

    //функция разворачивает и скрывает параметры
    const changeExpended = () => {
        setExpandedState(!expandedState)
    }

    const accordionContent = (
        <AccordionDetailsCus>
            {children}
        </AccordionDetailsCus>
    );

    const accordionTitleWithIcon = (
        <AccordionSummaryCus
            onClick={changeExpended}
            expandIcon={<ExpandMoreIcon />}
        >
            <Typography className={classes.title} variant="h1" component="h1">
                {title}
            </Typography>
        </AccordionSummaryCus>
    );

    const accordionTitle = (
        <Typography className={classes.title} variant="h1" component="h1">
            {title}
        </Typography>
    )

    return (
        <AccordionCus expanded={expandedState} className={classes.accordion}>
            <Box display="flex" justifyContent="space-between">
                <Box display="flex">
                    {
                        (statusTable === 'onlyInfo') ? '' : (
                            <Box>
                                <IconButtonCus disabled={backIsDisabled}><KeyboardArrowLeftIcon onClick={onBack}
                                    className={classes.icon} /></IconButtonCus>
                                <IconButtonCus disabled={nextIsDisabled}><KeyboardArrowRightIcon onClick={onNext}
                                    className={classes.icon} /></IconButtonCus>
                            </Box>
                        )
                    }
                    <Box>
                        {
                            (accordion) ? accordionTitleWithIcon : accordionTitle
                        }
                    </Box>
                </Box>
                {
                    (statusTable === 'onlyInfo') ? '' : (
                        <Box>
                            <IconButtonCus>
                                <SvgIcon
                                    onClick={onOpen}
                                    classes={{ root: classes.svgRoot }}
                                    component={FullscreenIcon} />
                            </IconButtonCus>
                            <IconButtonCus>
                                <CloseIcon onClick={onClose} className={classes.icon} />
                            </IconButtonCus>
                        </Box>
                    )
                }

            </Box>
            {
                (accordion) ? accordionContent : ''
            }
        </AccordionCus>
    )
}