import * as React from 'react';
import { Popover, Typography, Avatar, Box } from '@material-ui/core';
import AvatarDefault from './../../icons/avatar.svg';
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
    avatar: {
        width: "24px",
        height: "24px"
    },
    box: {
        marginLeft: "18px",
        '@media screen and (max-width:1060px)': {
            marginLeft: "0px",
            position: 'absolute',
            right: 12,
        },
    }
});

export default function Profile() {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Box
                className={classes.box}
                // onClick={handleClick}
                aria-describedby={id}
            >
                <Avatar
                    className={classes.avatar}
                    alt="Avatar"
                    src={AvatarDefault}
                />
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography>Test.</Typography>
            </Popover>
        </>
    );
}