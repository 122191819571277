import React from "react"
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { QueryParamProvider } from "use-query-params"
import Orders from "../pages/Orders"
import Services from './../pages/Services/index'
import Events from './../pages/Events/index'
import CitiesPage from "../pages/Cities"
import Notifications from "../pages/Notifications"
import Header from "./Header"
import CityInfo from "../pages/CityInfo"
import PerformerInfo from "../pages/Performers/PerformerInfoPage"
import PerformersPage from "./../pages/Performers/index"

const optionalBool = 'pushState' in window.history
const keyExtractor = (url: string) => url.split('/')[1]

export const DashboardRouter = () => {
    return (
        <BrowserRouter
            forceRefresh={!optionalBool}
        >
            <QueryParamProvider ReactRouterRoute={Route}>
                <Header />
                <main>
                    <Route
                        render={({ location }) => {
                            const { pathname } = location
                            return (
                                <TransitionGroup>
                                    <CSSTransition
                                        key={keyExtractor(pathname)}
                                        classNames="b2c-page"
                                        timeout={{
                                            enter: 200,
                                            exit: 200,
                                        }}
                                    >
                                        <Route
                                            location={location}
                                            render={() =>
                                                <Switch location={location}>
                                                    <Route
                                                        exact
                                                        path='/orders'
                                                        component={Orders}
                                                    />
                                                    <Route
                                                        exact
                                                        path='/cities'
                                                        component={CitiesPage}
                                                    />
                                                    <Route
                                                        exact
                                                        path='/cities/:cityid'
                                                        component={CityInfo}
                                                    />
                                                    <Route
                                                        exact
                                                        path='/performers'
                                                        component={PerformersPage}
                                                    />
                                                    <Route
                                                        exact
                                                        path='/performers/:performerid'
                                                        component={PerformerInfo}
                                                    />
                                                    <Route
                                                        exact
                                                        path='/services'
                                                        component={Services}
                                                    />
                                                    <Route
                                                        exact
                                                        path='/notifications'
                                                        component={Notifications}
                                                    />
                                                    <Route
                                                        exact
                                                        path='/events'
                                                        component={Events}
                                                    />
                                                </Switch>
                                            }
                                        />
                                    </CSSTransition>
                                </TransitionGroup>
                            )
                        }}
                    />
                </main>
            </QueryParamProvider>
        </BrowserRouter>
    )
}