import React from 'react'
import { useSelector } from 'react-redux';
import { PageInfoContainer } from '../../components/PageOnlyInfo';
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { PageDashboard } from "../../navigation/PageDashboard";
import { RootState } from '../../store';
import { getCityById } from '../../store/City/thunks';
import { CitiesInfoBlock } from "../Cities/CitiesInfoBlock";

export default function CityInfo(props: any) {
    const dispatch = useAppDispatch()
    const Item = useSelector((state: RootState) => state.city.activeItem)

    React.useEffect(() => {
        if (!Item) {
            dispatch(getCityById(props.match.params.cityid))
        }
    }, [dispatch, Item])
    return (
        <PageDashboard>
            <PageInfoContainer>
                {
                    Item && <CitiesInfoBlock statusInfo="onlyInfo" />
                }
            </PageInfoContainer>
        </PageDashboard>
    )
}