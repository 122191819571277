import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useWindowWidth } from '../utils/CheckWindowWidth'
import logo from "./../../icons/b2c-logo.svg";
import Profile from "./Profile";
import Search from "./Search";
import Nav from "./Nav";
import clsx from "clsx";

const useStyles = makeStyles({
  header: {
    height: 54,
    boxSizing: "border-box",
    padding: "12px 12px",
    display: "flex",
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 5,
    '@media screen and (max-width:1060px)': {
      flexWrap: 'wrap',
      height: 90,
      position: 'relative'
    },
    '@media screen and (max-width:560px)': {
      padding: "8px 0px",
    }
  },
  logo: {
    width: "20%",
    '@media screen and (max-width:1060px)': {
      width: 'auto'
    },
    '@media screen and (max-width:560px)': {
      paddingLeft: "12px",
    }
  },
  nav: {
    display: "flex",
    alignItems: 'center',
    justifyContent: "space-around",
    width: "50%",
    '@media screen and (max-width:1060px)': {
      width: '100%',
      order: 3,
      justifyContent: 'space-between',
      marginTop: 13
    },
    '@media screen and (max-width:560px)': {
      position: 'relative',
      justifyContent: 'left',
      overflowX: 'scroll',
      scrollbarWidth: 'none',
      '-webkit-mask-image': 'linear-gradient(90deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .25) 9px, rgba(0, 0, 0, 1) 18px, rgba(0, 0, 0, 1) calc(100% - 18px), rgba(0, 0, 0, .25) calc(100% - 9px), rgba(0, 0, 0, 0))',
      maskImage: 'linear-gradient(90deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .25) 9px, rgba(0, 0, 0, 1) 18px, rgba(0, 0, 0, 1) calc(100% - 18px), rgba(0, 0, 0, .25) calc(100% - 9px), rgba(0, 0, 0, 0))',
      padding: '0px 12px',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0
      },
    }
  },
  profile: {
    width: "30%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: "flex-end",
    '@media screen and (max-width:1060px)': {
      width: 'auto'
    },
    '@media screen and (max-width:560px)': {
      paddingLeft: "12px",
    },
    '& span': {
      fontSize: '14px',
      fontWeight: "500",
      lineHeight: "20px",
    }
  },
  scrollHeader: {
    background: '#EBF3F7',
    boxShadow: 'inset 0px -1px 0px #CAE1ED'
  },
  scrollHeaderMobile: {
    position: 'fixed',
    top: -91,
    left: 0,
    transition:'all .3s'

  },
  viewHeaderMobile: {
    top: 0,
    transition:'all .5s'
  }
});

export default function Header() {
  const [scrollCheck, setScrollCheck] = React.useState(false)
  const [mobileScrollTop, setMobileScrollTop] = React.useState(false)
  const [lastTopScroll, setLastTopScroll] = React.useState(0)
  const { windowWidth } = useWindowWidth()

  const classes = useStyles()

  const mainClassHeader = React.useMemo(() => {
    if (windowWidth <= 1200 && lastTopScroll > 100) {
      return clsx(classes.header, classes.scrollHeaderMobile, classes.scrollHeader, mobileScrollTop && classes.viewHeaderMobile)
    }
    return clsx(classes.header, scrollCheck && classes.scrollHeader)
  }, [scrollCheck, windowWidth, lastTopScroll, mobileScrollTop])

  React.useEffect(() => {
    const ScrollForPage = () => {
      if (windowWidth <= 1200) {
        let top = window.pageYOffset
        if (lastTopScroll > top) {
          setMobileScrollTop(true)

        } else if (lastTopScroll < top) {
          setMobileScrollTop(false)
        }
        setLastTopScroll(top)
      }

      if (window.pageYOffset > 10) {
        setScrollCheck(true)
      } else {
        setScrollCheck(false)
      }
    }
    document.addEventListener('scroll', ScrollForPage)
    return () => document.removeEventListener('scroll', ScrollForPage)
  }, [setScrollCheck, setLastTopScroll, setMobileScrollTop, lastTopScroll, windowWidth])
  return (
    <header className={mainClassHeader}>
      <div className={classes.logo}>
        <Link to="/">
          <img src={logo} alt="b2c" />
        </Link>
      </div>
      <nav className={classes.nav}>
        <Nav />
      </nav>
      <div className={classes.profile}>
        <Search />
        <Profile />
      </div>
    </header>
  );
}