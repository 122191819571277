import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import cityStore from './City/cityStore'
import performerStore from './Performers/performerStore'
import tableStore from './table/tableStore'

const store = configureStore({
    reducer: {
        table: tableStore,
        city: cityStore,
        performer: performerStore
    },
    middleware: getDefaultMiddleware => process.env.NODE_ENV === 'development'
        ? getDefaultMiddleware().concat(logger)
        : getDefaultMiddleware()
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store