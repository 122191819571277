import { TableRow, TableRowProps, withStyles } from "@material-ui/core"

const TableRowStyled = withStyles({
  root: {
    // transition: 'all .3s',
    borderRadius: '8px',
    border: 'none',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    '&.active td': {
      background: '#CAE1ED',
      '& span.gradientBefore': {
        background: '#CAE1ED'
      },
      '&.gradientBefore::before': {
        background: 'linear-gradient(270deg, #CAE1ED 13.64%, rgba(202, 225, 237, 0) 100%)'
      }
    },
    '&:hover.active': {
      cursor: 'default'
    },
    '&:hover.active td': {
      background: '#CAE1ED',
    },
    '&:hover td,&:hover span.gradientBefore,&:hover span.mobileBg': {
      background: '#EBF3F7'
    },
    '&:active td,&:active span.gradientBefore,&:active span.mobileBg': {
      background: '#CAE1ED'
    },
    '& span.gradientBefore,& span.mobileBg': {
      background: '#ffffff'
    },
    '& .gradientAfter::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '11px',
      height: '100%',
      background: 'linear-gradient(270deg, #FFFFFF 13.64%, rgba(255, 255, 255, 0) 100%)',
      top: 0,
      right: 0,
      zIndex: 2,
    },
    '& .gradientBefore::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: -11,
      display: 'block',
      width: '11px',
      height: '100%',
      background: 'linear-gradient(270deg, #FFFFFF 13.64%, rgba(255, 255, 255, 0) 100%)',
    },
    '&:hover .gradientBefore::before,&:hover .gradientAfter::after': {
      background: 'linear-gradient(270deg, #EBF3F7 13.64%, rgba(235, 243, 247, 0) 100%)'
    },
    '&:active .gradientBefore': {
      background: '#CAE1ED'
    },
    '&:active .gradientBefore::before,&:active .gradientAfter::after': {
      background: 'linear-gradient(270deg, #CAE1ED 13.64%, rgba(202, 225, 237, 0) 100%)'
    },
    '&:hover td svg path': {
      fill: '#74737B'
    },

    "& td": {
      fontSize: '16px',
      lineHeight: '22px',
      position: 'relative',
      border: 'none',
      height: '22px',
      padding: 0,
      background: '#ffffff',
      '& span.elemCell': {
        padding: '8px 0px 8px 8px',
        display: 'block'
      },
      "& span.elemOrderCell": {
        padding: '8px 0px 8px 0px',
        display: 'block'
      },
    },
    "& td:first-child": {
      borderRadius: '8px 0px 0px 8px',

      "& span.elemCell": {
        paddingLeft: 8
      }
    },
    "& td:last-child": {
      borderRadius: '0px 8px 8px 0px',

      '& span.elemCell': {
        padding: '8px'
      }
    }
  }
})(TableRow);
const MobileTableRow = withStyles({
  root: {
    borderRadius: '8px',
    border: 'none',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    '@media screen and (max-width:1200px)': {
      
      marginTop: 8
    },
    '&.active td': {
      background: '#CAE1ED',
      '& span.gradientBefore': {
        background: '#CAE1ED'
      },
      '&.gradientBefore::before': {
        background: 'linear-gradient(270deg, #CAE1ED 13.64%, rgba(202, 225, 237, 0) 100%)'
      }
    },
    '&:hover.active': {
      cursor: 'default'
    },
    '&:hover.active td': {
      background: '#CAE1ED',
    },
    '&:hover td,&:hover span.gradientBefore,&:hover span.mobileBg': {
      background: '#EBF3F7'
    },
    '&:active td,&:active span.gradientBefore,&:active span.mobileBg': {
      background: '#CAE1ED'
    },
    '& span.gradientBefore,& span.mobileBg': {
      background: '#ffffff'
    },
    '& .gradientAfter::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '11px',
      height: '100%',
      background: 'linear-gradient(270deg, #FFFFFF 13.64%, rgba(255, 255, 255, 0) 100%)',
      top: 0,
      right: 0,
      zIndex: 2,
    },
    '& .gradientBefore::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: -11,
      display: 'block',
      width: '11px',
      height: '100%',
      background: 'linear-gradient(270deg, #FFFFFF 13.64%, rgba(255, 255, 255, 0) 100%)',
    },
    '&:hover .gradientBefore::before,&:hover .gradientAfter::after': {
      background: 'linear-gradient(270deg, #EBF3F7 13.64%, rgba(235, 243, 247, 0) 100%)'
    },
    '&:active .gradientBefore': {
      background: '#CAE1ED'
    },
    '&:active .gradientBefore::before,&:active .gradientAfter::after': {
      background: 'linear-gradient(270deg, #CAE1ED 13.64%, rgba(202, 225, 237, 0) 100%)'
    },
    '&:hover td svg.arrow path': {
      fill: '#74737B'
    },

    "& td": {
      fontSize: '16px',
      lineHeight: '22px',
      position: 'relative',
      border: 'none',
      height: '22px',
      padding: 0,
      background: '#ffffff',
      '& span.elemCell': {
        padding: '8px 0px 8px 8px',
        display: 'block',
        '@media screen and (max-width:1200px)': {
          padding: 0,
        }
      },
      '@media screen and (max-width:1200px)': {
        padding: '4px 8px',
        height: 'auto'
      },
      '@media screen and (max-width:500px)': {
        overflow: 'hidden'
      },
      "& span.elemOrderCell": {
        padding: '8px 0px 8px 0px',
        display: 'block',
        '@media screen and (max-width:1200px)': {
          padding: 0,
        }

      },
    },
    "& td:first-child": {
      borderRadius: '8px 0px 0px 8px',
      '@media screen and (max-width:1200px)': {
        borderRadius: '0px',
      },
      "& span.elemCell": {
        paddingLeft: 8,
        '@media screen and (max-width:1200px)': {
          padding: 0,
        }
      }
    },
    "& td:last-child": {
      borderRadius: '0px 8px 8px 0px',
      '@media screen and (max-width:1200px)': {
        borderRadius: '0px',
      },
      '& span.elemCell': {
        padding: '8px',
        '@media screen and (max-width:1200px)': {
          padding: 0,
        }
      }
    }
  }
})(TableRow)

export type CommonTableRowProps<T> = TableRowProps & {
  item: T,
  specials: { special: boolean },
}

export const CommonTableRow = <T extends Object>(props: CommonTableRowProps<T>) => {
  return (
    <TableRowStyled {...props} />
  );
}
export const CammonTableRowMobile = <T extends Object>(props: CommonTableRowProps<T>) => {
  return (
    <MobileTableRow {...props} />
  );
}