import { Box, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles({
  tag: {
    background: '#EBF3F7',
    borderRadius: '8px',
    padding: '0px 5px 0px 2px'
  },
  text: {
    color: '#383838',
  },
  paddingText: {
    padding: '0px 2px'
  },
  count: {
    fontWeight: 300,
    color: '#74737B',
  }
})

interface TagLabelProps {
  title: string,
  count: number,
  className?: string,
}

export const TagLabel = ({ title, count, className }: TagLabelProps) => {
  const style = useStyles()
  return (
    <Box className={clsx(className, style.tag)}>
      <span className={style.text}>#</span>
      <span className={clsx(style.text, style.paddingText)}>{title}</span>
      <span className={style.count}>{count}</span>
    </Box>
  )
}