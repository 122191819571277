import React from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    link: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textDecoration: 'none',
        '@media screen and (max-width:560px)': {
            marginRight: 25
        },
        '@media screen and (max-width:522px)': {
            marginRight: 35
        },
        '@media screen and (max-width:434px)': {
            marginRight: 25
        },
        '@media screen and (max-width:394px)': {
            marginRight: 15
        },
        '@media screen and (max-width:355px)': {
            marginRight: 25
        },
        '& span': {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "22px",
            textAlign: "center",
            color: "#000000",
        }
    },
    activeLink: {
        '& span': {
            color: '#74737B',
        },
    }
});

interface NavBarLinkProps {
    title: string,
    url: string,
}

const isPathnameActive = (locationPathname: string, path: string) => {
    if (path === '/') {
        return path === locationPathname || locationPathname.startsWith('/link1');
    }

    return locationPathname.startsWith(path);
}

const NavBarLink = (props: NavBarLinkProps) => {
    const { title, url } = props;
    useRouteMatch();
    const history = useHistory();
    const classes = useStyles();
    const isActive = isPathnameActive(history.location.pathname, url);
    const className = clsx(classes.link, isActive && classes.activeLink);

    return (
        <Link
            className={className}
            to={url}>
            <span>{title}</span>
        </Link>
    );
};

export default function Nav() {
    return (
        <>
            <NavBarLink
                title={'Города'}
                url={'/cities'}
            />
            <NavBarLink
                title={'Исполнители'}
                url={'/performers'}
            />
            <NavBarLink
                title={'Услуги'}
                url={'/services'}
            />
            <NavBarLink
                title={'Записи'}
                url={'/orders'}
            />
            <NavBarLink
                title={'Уведомления'}
                url={'/notifications'}
            />
            <NavBarLink
                title={'События'}
                url={'/events'}
            />
        </>
    )
}