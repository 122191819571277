import { TwoColsPage } from '../../common/TwoColsPage'
import { TableCols } from '../../components/Table/TableCols';
import { PageDashboard } from '../../navigation/PageDashboard'
import { NotifTable } from './NotifTable';
import { useSelector } from 'react-redux'
import { RootState } from "../../store"
import React from 'react'

export default function Notifications() {
  const status = useSelector((state: RootState) => state.table.Notifications.status)
  const WW = React.useMemo(() => {
    return window.innerWidth
  }, [window.innerWidth])
  const [windowWidth, setWindowWidth] = React.useState(WW)
  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.onload = handleResize

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize ', handleResize);
  }, [])
  return (
    <PageDashboard>
      <TwoColsPage>
        <TableCols status={status}>
          <NotifTable windowWidth={windowWidth} />
        </TableCols>
      </TwoColsPage>
    </PageDashboard>
  )
}