import { createAsyncThunk } from "@reduxjs/toolkit"
import { Cities, CityInfoPerformers, CityInfoServices, City } from "../../entities/City"
import { apiUrlV1 } from '../../../config/config'

interface LoadCities {
  page?: number,
  search?: string,
  ordering?: string
}

export const loadCities = createAsyncThunk('city/load',
  async (params: LoadCities, thunkApi) => {
    const { search, page, ordering } = params
    const res = await fetch(`${apiUrlV1}/dashboard/cities/?ordering=${ordering}${page ? `&page=${page}` : ''}`)

    if (res.status !== 200) {
      return null
    }

    return (await res.json()) as Promise<Cities>
  }
)
export const addNextPage = createAsyncThunk('city/load/:page',
  async (params: string, thunkApi) => {
    const res = await fetch(params)
    if (res.status !== 200) {
      return null
    }
    return (await res.json()) as Promise<Cities>
  })

export const PerformersForCity = createAsyncThunk(
  'city/performers/id',
  async (params: number): Promise<CityInfoPerformers | any> => {
    const res = await fetch(`${apiUrlV1}/dashboard/performers/?city=${params}`)

    if (res.status === 200) {
      return await res.json()
    }
    return null
  }
)
export const ServicesForCity = createAsyncThunk(
  'city/services/id',
  async (params: number): Promise<CityInfoServices | any> => {
    const res = await fetch(`${apiUrlV1}/dashboard/services/?city=${params}`)

    if (res.status === 200) {
      return await res.json()
    }
    return null
  }
)
export const getCityById = createAsyncThunk(
  'cities/id',
  async (param: string, thunkAPI): Promise<City> => {
    const res = await fetch(`${apiUrlV1}/dashboard/cities/${param}`)

    if (res.status === 200) {
      return res.json()
    }
    return {
      id: 0,
      title: '',
      region: '',
      rating: 0,
      population: 0,
      workersCount: 0
    }
  }
)