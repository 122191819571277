import { Box, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyle = makeStyles({
  root: {
    margin: '14px auto 0px auto ',
    padding: 0,
    width: '99%',
    position: 'relative'
  },
  insertBox: {
    borderRadius: '8px',
    padding: '12px 4px 20px 4px',
    background: '#FFFFFF',
  }
})

export const PageInfoContainer = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyle()
  return (
    <Box className={classes.root}>
      <Box className={classes.insertBox}>
        {children}
      </Box>
    </Box>
  )
}