import { Table, withStyles } from "@material-ui/core";

export const CommonTable = withStyles({
  root: {
    background: '#fff',
    "& tr th": {
      paddingLeft: '8px',
      "&:first-child": {
        paddingLeft: 0
      },
      "&:last-child": {
        paddingRight: 8
      }
    }
  }
})(Table);
