import { Box, makeStyles } from '@material-ui/core'
import { TagLabel } from '../../TagLabel'

const useStyleTags = makeStyles({
  list: {
    listStyle: 'none',
    display: 'flex',
    padding: 0,
    margin: 0,
    "& li": {
      margin: '0px 2px'
    },
    "& li:last-child": {
      margin: '0px 0px 0px 2px'
    },
    "& li:first-child": {
      margin: '0px 2px 0px 0px'
    },
  }
})

interface Targs {
  tags?: {
    title: string,
    count: number
  }[]
}

export const TagsList = ({ tags }: Targs) => {
  const classes = useStyleTags()
  return (
    <Box>
      <ul className={classes.list}>
        {tags &&
          tags.map((item: { title: string, count: number }, index: number) => <li key={index}><TagLabel title={item.title} count={item.count} /></li>)
        }
      </ul>
    </Box>
  )
}