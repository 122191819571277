import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles({
  hover: {
    transition: 'all .3s',
    '& td': {
      background: '#ffffff'
    },
    '&:hover td': {
      background: '#EBF3F7'
    },
    '&:active td': {
      background: '#CAE1ED'
    },
    '& span.gradientBefore': {
      background: '#ffffff'
    },
    '&:hover .gradientBefore': {
      background: '#EBF3F7'
    },
    '&:active .gradientBefore': {
      background: '#CAE1ED'
    },
    '& .gradientBefore::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: -11,
      display: 'block',
      width: '11px',
      height: '100%',
      background: 'linear-gradient(270deg, #FFFFFF 13.64%, rgba(255, 255, 255, 0) 100%)',
    },
    '&:hover .gradientBefore::before': {
      background: 'linear-gradient(270deg, #EBF3F7 13.64%, rgba(235, 243, 247, 0) 100%)'
    },
    '&:active .gradientBefore::before': {
      background: 'linear-gradient(270deg, #CAE1ED 13.64%, rgba(202, 225, 237, 0) 100%)'
    },
    '&:hover td svg path': {
      fill: '#74737B'
    }
  },
  gradientBefore: {
    background: '#EBF3F7',
    "&::before": {
      content: '""',
      position: 'absolute',
      top: 0,
      left: -11,
      display: 'block',
      width: '11px',
      height: '100%',
      background: 'linear-gradient(270deg, #FFFFFF 13.64%, rgba(255, 255, 255, 0) 100%)'
    }
  },
  arrowFlex: {
    display: 'flex',
    alignItems: 'center'
  },
  threePoint: {
    width: 16
  },
  textBold: {
    fontWeight: 500,
  },
  textLight: {
    fontWeight: 300,
  },
  textLightGray: {
    fontWeight: 300,
    color: '#74737B'
  },
  textRight: {
    textAlign: 'right',
  },
  textColorGG: {
    color: '#383838'
  },
  textColorFG: {
    color: '#74737B'
  },
  textColorB: {
    color: '#000'
  },
  RateLow: {
    '& path': {
      fill: '#FB2924'
    }
  },
  RateMiddle: {
    '& path': {
      fill: '#FDAF2D'
    }
  },
  RateHigh: {
    '& path': {
      fill: '#0ED609'
    }
  },
  overflow: {
    maxWidth: 148,
    overflow: 'hidden'
  },
  marginLeft_8: {
    marginLeft: 8,
  },
  pricePos: {
    position: 'relative',
    width: 0,
    "& span": {
      position: 'absolute',
      top: 0,
      right: 0,
      display: "flex",
      height: '100%',
      alignItems: 'center'
    }
  },
  maxWidth: {
    maxWidth: 148,
  },
  maxWidthMobile: {
    "@media screen and (max-width:1280px)": {
      maxWidth: 100
    }
  },
  statusBarFull: {
    width: 132
  },
  statusBarSmall: {
    width: 16
  },
  tableMobile: {
    "@media screen and (max-width:500px)": {
      display: 'block',
      '& thead': {
        display: 'block',
      },
      '& tbody': {
        display: 'block',
      }
    }
  },
  nameMobile: {
    "@media screen and (max-width:500px)": {
      maxWidth: '49%',
      position: 'relative',
    }
  },
  arrowNameMobile: {
    '@media screen and (max-width:1200px)': {
      '&:before': {
        display: 'none !important'
      }
    },
    "@media screen and (max-width:500px)": {
      '&:before': {
        display: 'block !important'
      },
      position: 'relative',
      zIndex: 1,
    }
  },
  postArowwMobile: {
    "@media screen and (max-width:500px)": {
      position: 'relative',
      zIndex: 1,
    }
  },
  serviceMobile: {
    "@media screen and (max-width:500px)": {
      maxWidth: '60%',
    }
  }
})