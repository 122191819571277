import { createAsyncThunk } from "@reduxjs/toolkit"
import { Performers } from "../../entities/Performer"

export const fetchPerformersTable = createAsyncThunk(
  'performers/fetchPerformers',
  async () => {
    const performersListRes = await fetch('https://test.b2c.by/api/v1/dashboard/performers/')

    if (performersListRes.status !== 200) {
      return null
    }
    return (await performersListRes.json()) as Promise<Performers>
  }
)