import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { NumberDigits } from "../../utils/NumberRender";

const useStyles = makeStyles({
    itemName: {
        fontSize: '16px',
        lineHeight: '22px',
        color: '#000000'
    },
    itemValue: {
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 'bold',
    },
    gridContainer: {
        marginTop: "20px",
        "& >div": {
            marginBottom: '10px',
        }
    }
})

export const ParameterItem = ({ params }: { params: Array<{ name: string; value?: string | number | React.ReactNode; txtColor?: string }> }) => {
    const elems = params.length
    const style = useStyles()
    const smValue = React.useMemo(() => {
        if (elems === 2) {
            return 4
        }
        if (elems === 3) {
            return 3
        }
        return 10
    }, [params.length])
    const smTitle = React.useMemo(() => {
        if (elems === 3) {
            return 1
        }
        return 2
    }, [params.length])


    return (
        <>
            {
                params.map(item => (
                    <>
                        <Grid sm={smTitle}>
                            <Typography className={style.itemName} component="p">
                                {item.name}
                            </Typography>
                        </Grid>
                        <Grid sm={smValue}>
                            <Typography className={`${style.itemValue} ${item.txtColor || 'text-black'}`} component="p">
                                {item.value}
                            </Typography>
                        </Grid>
                    </>
                ))
            }
        </>
    )
}

export const Parameter = ({ children }: { children: ReactNode }) => {
    const style = useStyles()
    return (
        <Grid container>
            <Grid className={style.gridContainer} sm={12} container>
                {children}
            </Grid>
        </Grid>
    );
}