import {v4 as uuidv4} from 'uuid';

export interface ImageFile {
  id: number,
  image: string,
  thumbnail?: string,
  local?: string,
  createdAt: string,
}

export type ImageFileFeatured = ImageFile & {
  uuid: string,
};

export const imageFileToFeatured = (imageFile: ImageFile): ImageFileFeatured => {
  return {...imageFile, uuid: uuidv4()};
}

export const thumbnailFirst = (imageFile?: ImageFile) => {
  return imageFile?.thumbnail || imageFile?.image;
}