import {Box, makeStyles} from "@material-ui/core";
import {TagLabel} from "../TagLabel";

const useStyleTags = makeStyles({
    list: {
        listStyle: 'none',
        display: 'flex',
        flexWrap: 'wrap',
        padding: 0,
        margin: 0,
        "& li": {
            margin: '5px 2px'
        },
        "& li:last-child": {
            margin: '5px 0px 5px 2px'
        },
        "& li:first-child": {
            margin: '5px 2px 5px 0px'
        },
    }
})

export const TagsList = ({tags}: { tags: { title: string, count: number }[] }) => {
    const classes = useStyleTags()
    return (
        <Box>
            <ul className={classes.list}>
                {
                    tags.map((item: any) => <li><TagLabel title={item.title} count={item.count}/></li>)
                }
            </ul>
        </Box>
    )
}
