import React from 'react'
import { TwoColsPage } from '../../common/TwoColsPage'
import { TableCols } from '../../components/Table/TableCols'
import { PageDashboard } from '../../navigation/PageDashboard'
import { PerformancesTable } from "./PerformancesTable"
import { useSelector } from 'react-redux'
import { RootState } from "../../store"
import { InfoCols } from "../../common/InfoCols"
import { PerformersInfoBlock } from "./PerformersInfoBlock"
import { useAppSelector } from '../../hooks/useAppSelector'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useWindowWidth } from '../../utils/CheckWindowWidth'
import { selectPerformerList, statusLoadPage } from '../../store/Performers/performerStore'
import { getProfilesById, loadPerformers, nextPagePerformer } from '../../store/Performers/thunks'
import { Pagination } from '../../components/Table/Pagination'
import { TableBox } from '../../components/Table/TableBox'
import { Performers } from '../../entities/Performer'

export default function PerformersPage() {
  const dispatch = useAppDispatch()
  const performerList = useAppSelector(selectPerformerList)
  const { status, pagination, listLoading, activeItemIndex, activeItem, activeUserLoading } = useSelector((state: RootState) => state.performer)

  const { windowWidth } = useWindowWidth()


  const PageNext = React.useCallback(() => {
    if (performerList.length >= 1 && !listLoading) {
      dispatch(nextPagePerformer(performerList[performerList.length - 1].next))
    }
  }, [dispatch, performerList, listLoading])

  React.useEffect(() => {
    const doc = document.documentElement
    const ScrollForPage = () => {
      if (window.pageYOffset + window.innerHeight >= doc.scrollHeight - 100) {
        PageNext()
      }
    }
    document.addEventListener('scroll', ScrollForPage)
    return () => document.removeEventListener('scroll', ScrollForPage)
  }, [PageNext])
  React.useEffect(() => {
    if (performerList.length === 0) {
      dispatch(loadPerformers({ search: '', page: 0, ordering: '-rating' }))
    }
  }, [dispatch, performerList])

  React.useEffect(() => {
    if (status === 'onlyInfo') {
      dispatch(statusLoadPage({ status: 'twoInOne' }))
    }
  }, [status])

  const updateProfile = React.useCallback(() => {
    if (activeItem && !activeUserLoading) {
      dispatch(getProfilesById(activeItem?.id))
    }
  }, [dispatch, activeItem, activeUserLoading])

  React.useEffect(() => {
    updateProfile()
  }, [updateProfile, activeItemIndex])

  return (
    <PageDashboard>
      <TwoColsPage>
        <TableBox status={status}>
          {
            performerList.map((item: Performers, index: number) => {
              const { results, next } = item
              return (
                <>
                  <TableCols key={index}>
                    <PerformancesTable windowWidth={windowWidth} performerState={results ? results : []} loading={false} />
                  </TableCols>
                  {next && <Pagination status={status} pagination={pagination ? pagination : []} activeCount={index} />}
                </>
              )
            })
          }
        </TableBox>
        <InfoCols status={status}>
          <PerformersInfoBlock statusInfo={status} />
        </InfoCols>
      </TwoColsPage>
    </PageDashboard>
  )
}