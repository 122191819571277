interface FullNameProps {
  data: {
    firstname?: string,
    lastname?: string,
    patronymic?: string
  }
}

export const FullName = ({ data }: FullNameProps) => {
  const { firstname,
    lastname,
    patronymic } = data

  return (
    <span>
      {`${lastname ? lastname : ''} ${firstname ? firstname : ''} ${patronymic ? patronymic : ''}`}
    </span>
  )
}