import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { CityInfoPerformers, CityInfoServices } from '../../entities/City'
import { Orders } from '../../entities/Order'
import { Performer, Performers } from '../../entities/Performer'
import { Services } from '../../entities/Service'
import { fetchCitiesTable, fetchCityById, fetchCityInfoPerformers, fetchCityInfoServices } from './cityTableLoad'
import { fetchOrdersTable } from './orderLoad'
import { fetchPerformersTable } from './performerLoad'
import { fetchServiceTable } from './servicesLoad'

interface CitiesLoad {
  count: number,
  next: string,
  previous?: string | null,
  results: {
    id: number,
    title: string,
    region: string,
    rating: number,
    population: number,
    workersCount: number
  }[] | null
}
// Define a type for the slice state
interface Table {
  Cities: {
    status: string,
    activeItem?: any,
    activeItemId?: any,
    activeItemIndex?: number,
    performerList?: CityInfoPerformers | null,
    serviceList?: CityInfoServices | null
  },
  Notifications: {
    status: string,
    activeItem?: any
  },
  Orders: {
    status: string,
    activeItem?: any
  },
  Performers: {
    status: string,
    activeItem?: any
    activeItemId?: any,
    activeItemIndex?: number,
  },
  Services: {
    status: string,
    activeItem?: any
  },
  Events: {
    status: string,
    activeItem?: any
  },
  CitiesState: CitiesLoad | null,
  ServicesState: Services | null,
  PerformersState: Performers | null,
  CitiesInfoPerf: CityInfoPerformers | null,
  OrdersState: Orders | null
}

// Define the initial state using that type
const initialState: Table = {
  Cities: {
    status: 'onlyTable'
  },
  Notifications: {
    status: 'onlyTable'
  },
  Orders: {
    status: 'onlyTable'
  },
  Performers: {
    status: 'onlyTable'
  },
  Services: {
    status: 'onlyTable'
  },
  Events: {
    status: 'onlyTable'
  },
  CitiesState: {
    count: 0,
    next: '',
    previous: null,
    results: null
  },
  CitiesInfoPerf: {
    count: 0,
    next: '',
    previous: null,
    results: null
  },
  ServicesState: {
    count: 0,
    next: '',
    previous: null,
    results: null
  },
  PerformersState: {
    count: 0,
    next: '',
    previous: null,
    results: null
  },
  OrdersState: {
    count: 0,
    next: '',
    previous: null,
    results: null
  }
}

export const tableStore = createSlice({
  name: 'columnStatus',
  initialState,
  reducers: {
    setCitiesStatus: (state, action: PayloadAction<{ status: string; id: number, index?: number }>) => {
      state.Cities.status = action.payload.status
      state.Cities.activeItemId = action.payload.id
      state.Cities.activeItemIndex = action.payload.index
    },
    setCitiesItem: (state, action: PayloadAction<{ item: any }>) => {
      state.Cities.activeItem = action.payload.item
    },
    setCitiesFull: (state, action: PayloadAction<{ status: string; id: number, index?: number, item: any }>) => {
      state.Cities.activeItem = action.payload.item
      state.Cities.activeItemId = action.payload.id
      state.Cities.activeItemIndex = action.payload.index
      state.Cities.status = action.payload.status
    },
    setCityActiveId: (state, action: PayloadAction<{ id: string }>) => {
      state.Cities.activeItem = action.payload.id
    },
    updateCityActiveIndex: (state, action: PayloadAction<{ id: string, index: number }>) => {
      state.Cities.activeItem = action.payload.id
      state.Cities.activeItemIndex = action.payload.index
    },
    setNotificationsStatus: (state, action: PayloadAction<{ status: string; id: string }>) => {
      state.Notifications.status = action.payload.status
      state.Notifications.activeItem = action.payload.id
    },
    setOrdersStatus: (state, action: PayloadAction<{ status: string; id: string }>) => {
      state.Orders.status = action.payload.status
      state.Orders.activeItem = action.payload.id
    },
    setPerformersStatus: (state, action: PayloadAction<{ status: string, id?: string, }>) => {
      state.Performers.status = action.payload.status
      state.Performers.activeItemId = action.payload.id
    },
    setPerformersAciveItem: (state, action: PayloadAction<{ status: string; id?: number, index?: number, item: Performer }>) => {
      state.Performers.status = action.payload.status
      state.Performers.activeItem = action.payload.item
      state.Performers.activeItemIndex = action.payload?.index
      state.Performers.activeItemId = action.payload?.id
    },
    setServicesStatus: (state, action: PayloadAction<{ status: string; id: string }>) => {
      state.Services.status = action.payload.status
      state.Services.activeItem = action.payload.id
    },
    setEventsStatus: (state, action: PayloadAction<{ status: string; id: string }>) => {
      state.Events.status = action.payload.status
      state.Events.activeItem = action.payload.id
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCitiesTable.fulfilled, (state: Draft<Table>, action) => {
      state.CitiesState = action.payload
    })
    builder.addCase(fetchCityById.fulfilled, (state: Draft<Table>, action) => {
      state.Cities.activeItem = action.payload
      state.Cities.status = 'onlyInfo'
    })
    builder.addCase(fetchCityInfoPerformers.fulfilled, (state: Draft<Table>, action) => {
      // state.CitiesInfoPerf = action.payload
      state.Cities.performerList = action.payload
    })
    builder.addCase(fetchCityInfoServices.fulfilled, (state: Draft<Table>, action) => {
      state.Cities.serviceList = action.payload
    })
    builder.addCase(fetchPerformersTable.fulfilled, (state: Draft<Table>, action) => {
      state.PerformersState = action.payload
    })
    builder.addCase(fetchServiceTable.fulfilled, (state: Draft<Table>, action) => {
      state.ServicesState = action.payload
    })
    builder.addCase(fetchOrdersTable.fulfilled, (state: Draft<Table>, action) => {
      state.OrdersState = action.payload
    })
  }
})

export const { setCitiesStatus,
  setCitiesItem,
  setCityActiveId,
  updateCityActiveIndex,
  setCitiesFull,
  setNotificationsStatus,
  setOrdersStatus,
  setPerformersStatus,
  setPerformersAciveItem,
  setServicesStatus,
  setEventsStatus } = tableStore.actions

export const selectCount = (state: RootState) => state.table
export const selectCitiesTable = (state: RootState) => state.table.CitiesState
export const selectPerformersByCityId = (state: RootState) => state.table.CitiesInfoPerf
export const selectPerformers = (state: RootState) => state.table.PerformersState
export const selectServices = (state: RootState) => state.table.ServicesState
export const selectOrder = (state: RootState) => state.table.OrdersState


export default tableStore.reducer