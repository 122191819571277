import {TableCell, TableCellProps, withStyles} from "@material-ui/core";
import React from "react";

const TableCellStyled = withStyles({

})(TableCell);

export type CommonTableCellProps<T> = TableCellProps & {
  item: T,
}

export const CommonTableCell = <T extends Object>(props: CommonTableCellProps<T>) => {
  return (
    <TableCellStyled {...props}/>
  );
}
