import React from 'react'
import clsx from 'clsx'
import { Box, Button, makeStyles, IconButton } from "@material-ui/core"
import { ReactComponent as ArrowRightIcon } from '../../../../icons/icon-arrowRight.svg'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import MenuIcon from '@material-ui/icons/Menu'
import { useWindowWidth } from '../../../utils/CheckWindowWidth'


const useStylePagination = makeStyles({
  root: {
    height: 46,
    width: '100%',
    margin: '3px 0px',
    display: 'flex'
  },
  pagList: {
    width: '100%',
    // background: '#000',
    height: '100%',
    maskImage: 'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .25) 10%, rgb(0, 0, 0) 30%, rgb(0, 0, 0) calc(100% - 30%), rgba(0, 0, 0, .25) calc(100% - 10%), rgba(0, 0, 0, 0) 100%)',
    '-webkit-mask-image': 'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .25) 10%, rgb(0, 0, 0) 30%, rgb(0, 0, 0) calc(100% - 30%), rgba(0, 0, 0, .25) calc(100% - 10%), rgba(0, 0, 0, 0) 100%)',
    display: 'flex',
    // justifyContent: 'center',
    overflow: 'hidden',
    '& p': {
      padding: '0px 29.5px',
      margin: 0,
      whiteSpace: 'nowrap',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '22px',
      color: '#74737B',
    }
  },
  paginationBox: {
    width: '100%',
    height: `100%`,
    display: 'flex',
    alignItems: 'center'
  },
  buttonLeft: {
    marginRight: 32,
  },
  buttonRight: {
    marginLeft: '32px'
  },
  btnText: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    whiteSpace: 'nowrap'
  },
  arrowPag: {
    display: 'flex',
    alignItems: 'center',
    '& path': {
      fill: '#74737B'
    }
  },
  iconArrowUp: {
    color: '#2475FB'
  },
  iconMenu: {
    color: '#2475FB'
  },

})
const useStyleTextBg = makeStyles({
  activeItemColor: {
    // background: `linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 0%)`,
    display: 'block',
    backgroundClip: 'text',
    "-webkit-background-clip": 'text',
    "-webkit-text-fill-color": 'transparent',
    color: '#808087',
  },
  nextItemColor: {
    // background: 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)',
    display: 'block',
    backgroundClip: 'text',
    "-webkit-background-clip": 'text',
    "-webkit-text-fill-color": 'transparent',
    color: '#74737B',
  }
})
interface PaginationProps {
  type: string,
  page: {
    id: number,
    title: string,
    active: boolean,
    next: boolean
  }[]
}

export const Pagination = ({ pagination, activeCount, status }: { pagination?: { id: number, title: string }[], activeCount: number, status?: string }) => {
  const classes = useStylePagination()
  const classesBg = useStyleTextBg()
  const [posLeft, setPosLeft] = React.useState<Number>(0)
  const [topProcent, setTopProcent] = React.useState<Number>(100)
  const divRef = React.useRef<HTMLHeadingElement>(null)
  const { windowWidth } = useWindowWidth()

  const columnStatus = React.useMemo(() => {
    if (windowWidth <= 1200) {
      return true
    }
    if (status === 'onlyTable') {
      return false
    }
    if (status === 'twoInOne') {
      return true
    }
  }, [status, windowWidth])

  const PositionLeft = React.useCallback((left: number) => {
    setPosLeft(left)
  }, [setPosLeft])

  const scrollAllTop = () => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  React.useEffect(() => {
    const el = divRef.current
    const ch = el?.children
    const activeIndex = () => {
      var i = 0
      if (pagination) {
        pagination.map((item: any, index: number) => {
          if (index === activeCount) i = index
          return null
        })
      }
      return i
    }
    const activeItem: any = ch && ch[activeIndex()]
    PositionLeft(activeItem?.offsetWidth + activeItem?.offsetLeft + 8.5)

  }, [divRef, pagination])

  const PositionScrollTop = React.useCallback((topProcent: number) => {
    if (topProcent >= 0 && topProcent <= 100) {
      setTopProcent(topProcent)
    }
  }, [setTopProcent])
  const Test_One = (n: any) => {
    return 100 - n
  }
  const Test_Two = (n: any) => {
    return 0 + n
  }
  React.useEffect(() => {
    const el = divRef.current

    const ScrollPage = (event: any) => {
      var itemClientRect: any = el?.getBoundingClientRect().top && el?.getBoundingClientRect().top
      var x = (itemClientRect * 100) / window.innerHeight

      PositionScrollTop(x)
    }
    document.addEventListener('scroll', ScrollPage)
    return () => document.removeEventListener('scroll', ScrollPage)
  }, [])

  return (
    <Box className={classes.root}>
      {!columnStatus ?
        <Button variant="text" onClick={scrollAllTop} className={clsx(classes.buttonLeft, classes.btnText)}>В начало</Button>
        :
        <IconButton onClick={scrollAllTop} ><KeyboardArrowUpIcon className={classes.iconArrowUp}></KeyboardArrowUpIcon></IconButton>
      }
      <Box className={classes.pagList}>
        <div ref={divRef} className={clsx(classes.paginationBox)} style={{ transform: `translateX(calc(50% - ${posLeft}px))` }}>
          {/* {
            page.map(item => {
              if (!item.active && !item.next) {
                return <p key={item.id}>{item.title}</p>
              }
              if (item.active) {
                return <>
                  <p key={item.id}>
                    <span className={classesBg.activeItemColor} style={{ backgroundImage: `linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) ${Test_One(topProcent)}%)` }}>
                      {item.title}
                    </span>
                  </p>
                  <Box className={classes.arrowPag}>
                    <ArrowRightIcon />
                  </Box>
                </>
              }
              if (item.next) {
                return <p key={item.id} >
                  <span className={classesBg.nextItemColor} style={{ backgroundImage: `linear-gradient(-90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) ${Test_Two(topProcent)}%)` }}>
                    {item.title}
                  </span>
                </p>
              }
            })
          } */}
          {
            pagination &&
            pagination.map((item, index) => {
              if (index != activeCount && index != (activeCount + 1)) {
                return <p key={item.id}>{item.title}</p>
              }
              if (index === activeCount) {
                return <>
                  <p key={item.id}>
                    <span className={classesBg.activeItemColor} style={{ backgroundImage: `linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) ${Test_One(topProcent)}%)` }}>
                      {item.title}
                    </span>
                  </p>
                  <Box className={classes.arrowPag}>
                    <ArrowRightIcon />
                  </Box>
                </>
              }
              if (index === (activeCount + 1)) {
                return <p key={item.id} >
                  <span className={classesBg.nextItemColor} style={{ backgroundImage: `linear-gradient(-90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) ${Test_Two(topProcent)}%)` }}>
                    {item.title}
                  </span>
                </p>
              }
            })
          }
        </div>
      </Box>
      {!columnStatus ?
        <Button variant="text" className={clsx(classes.btnText, classes.buttonRight)}>Выбрать</Button>
        :
        <IconButton><MenuIcon className={classes.iconMenu}></MenuIcon></IconButton>
      }
    </Box>
  )
}