import React from 'react';
import { TwoColsPage } from "../../common/TwoColsPage"
import { TableCols } from "../../components/Table/TableCols"
import { PageDashboard } from "../../navigation/PageDashboard"
import { ServicesTable } from './ServicesTable'
import { useSelector } from 'react-redux'
import { RootState } from "../../store"
import { selectServices } from "../../store/table/tableStore"
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { fetchServiceTable } from '../../store/table/servicesLoad';

export default function Services() {
  const dataServicesFetch = useAppSelector(selectServices)
  const dispatch = useAppDispatch()
  const status = useSelector((state: RootState) => state.table.Services.status)
  const WW = React.useMemo(() => {
    return window.innerWidth
  }, [window.innerWidth])
  const [windowWidth, setWindowWidth] = React.useState(WW)
  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.onload = handleResize

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize ', handleResize);
  }, [])
  React.useEffect(() => {
    if (!dataServicesFetch?.results) {
      dispatch(fetchServiceTable())
    }
  }, [dispatch, dataServicesFetch?.results])
  return (
    <PageDashboard>
      <TwoColsPage>
        <TableCols status={status}>
          <ServicesTable windowWidth={windowWidth} />
        </TableCols>
      </TwoColsPage>
    </PageDashboard>
  )
}