import { createAsyncThunk } from "@reduxjs/toolkit"
import { RootState } from "../index"
import { CityInfoPerformers, CityInfoServices } from "../../entities/City"

interface Cities {
  count: number,
  next: string,
  previous?: string | null,
  results: {
    id: number,
    title: string,
    region: string,
    rating: number,
    population: number,
    tags?: { title: string; count: number; }[]
    workersCount: number
  }[] | null
}
interface City {
  id: number,
  title: string,
  region: string,
  rating: number,
  population: number,
  tags?: { title: string; count: number; }[]
  workersCount: number
}
export const fetchCitiesTable = createAsyncThunk(
  'cities/fetchCitiesTable',
  async (params?: string) => {
    // const state = thunkAPI.getState() as RootState
    const citiesTableRes = await fetch(`https://test.b2c.by/api/v1/dashboard/cities/?${params ? `search=${params}` : `ordering=-rating`}`)

    if (citiesTableRes.status !== 200) {
      return null
    }

    return (await citiesTableRes.json()) as Promise<Cities>

  }
)

export const fetchCityById = createAsyncThunk(
  'cities/id',
  async (param: string, thunkAPI): Promise<City> => {
    const res = await fetch(`https://test.b2c.by/api/v1/dashboard/cities/${param}`)

    if (res.status === 200) {
      return res.json()
    }
    return {
      id: 0,
      title: '',
      region: '',
      rating: 0,
      population: 0,
      workersCount: 0
    }
  }
)
export const fetchCityInfoPerformers = createAsyncThunk(
  'city/performers/id',
  async (params: string): Promise<CityInfoPerformers | any> => {
    const res = await fetch(`https://test.b2c.by/api/v1/dashboard/performers/?city=${params}`)

    if (res.status === 200) {
      return await res.json()
    }
    return null
  }
)
export const fetchCityInfoServices = createAsyncThunk(
  'city/services/id',
  async (params: string): Promise<CityInfoServices | any> => {
    const res = await fetch(`https://test.b2c.by/api/v1/dashboard/services/?city=${params}`)

    if (res.status === 200) {
      return await res.json()
    }
    return null
  }
)