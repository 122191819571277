import {makeStyles} from "@material-ui/core/styles";
import React from "react";

const linearGradient = 'linear-gradient(45deg, #00B3FF 0%, #6A11CB 100%)';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: linearGradient,
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    boxShadow: "none",
  }
});

type GradientImageProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export const GradientImage = (props: GradientImageProps) => {
  const classes = useStyles();
  const backgroundImage = props.src
    ? `url(${props.src})`
    : linearGradient;

  return (
    <div style={{position: 'relative'}}>
      <div
        {...props}
        className={`${classes.root} ${props.className} ${classes.absolute}`}
        style={{...props.style, backgroundImage: linearGradient}}
      />
      <div
        className={`${classes.root} ${props.className}`}
        style={{...props.style, backgroundImage}}>
      </div>
    </div>
  );
};