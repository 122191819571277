import { createAsyncThunk } from "@reduxjs/toolkit"
import { apiUrlV1 } from "../../../config/config"
import { Performer, Performers, Profile } from "../../entities/Performer"

interface LoadPerformer {
  page?: number,
  search?: string,
  ordering?: string
}

export const loadPerformers = createAsyncThunk(
  'performer/load',
  async (params: LoadPerformer) => {
    const { page } = params
    const res = await fetch(`${apiUrlV1}/dashboard/performers/?ordering=-rating${page ? `&page=${page}` : ''}`)

    if (res.status !== 200) {
      return null
    }
    return (await res.json()) as Promise<Performers>
  }
)
export const nextPagePerformer = createAsyncThunk('performer/load/:page',
  async (params: string) => {
    const res = await fetch(params)
    if (res.status !== 200) {
      return null
    }
    return (await res.json()) as Promise<Performers>
  })
export const getPerformerById = createAsyncThunk(
  'performer/:id',
  async (param: string): Promise<Performer> => {
    const res = await fetch(`${apiUrlV1}/dashboard/performers/${param}`)

    if (res.status === 200) {
      return res.json()
    }
    return {
      id: 0,
      rating: 0,
      firstName: '',
      lastName: '',
      city: '',
      ordersCount: 0,
    }
  }
)
export const getProfilesById = createAsyncThunk(
  'profile/:id',
  async (id: number) => {
    const res = await fetch(`${apiUrlV1}/profiles/${id}`)

    if (res.status !== 200) {
      return undefined
    }
    return (await res.json()) as Promise<Profile>
  }
)