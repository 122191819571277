import React from 'react'
import { useSelector } from 'react-redux'
import { TwoColsPage } from '../../common/TwoColsPage'
import { Pagination } from '../../components/Table/Pagination'
import { Cities } from '../../entities/City'
import { CitiesInfoBlock } from './CitiesInfoBlock'
import { InfoCols } from '../../common/InfoCols'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'
import { PageDashboard } from '../../navigation/PageDashboard'
import { RootState } from '../../store'
import { selectCities, statusLoadPage } from '../../store/City/cityStore'
import { addNextPage, loadCities } from '../../store/City/thunks'
import { useWindowWidth } from '../../utils/CheckWindowWidth'
import { CitiesRowFilters } from '../Cities/CitiesRowFilters'
import { CitiesTable } from './CitiesTable'
import { TableCols } from '../../components/Table/TableCols'
import { TableBox } from '../../components/Table/TableBox'

export default function CitiesPage() {
  const citiesListState = useAppSelector(selectCities)
  const statusTable = useSelector((state: RootState) => state.city.status)
  const { pagination, listLoading } = useSelector((state: RootState) => state.city)
  const dispatch = useAppDispatch()
  const { windowWidth } = useWindowWidth()

  React.useEffect(() => {
    if (citiesListState.length === 0) {
      dispatch(loadCities({ search: '', page: 0, ordering: '-rating' }))
    }
  }, [dispatch, citiesListState])

  const PageNext = React.useCallback(() => {
    if (citiesListState.length >= 1 && !listLoading) {
      dispatch(addNextPage(citiesListState[citiesListState.length - 1].next))
    }
  }, [dispatch, citiesListState, listLoading])

  React.useEffect(() => {
    const doc = document.documentElement
    const ScrollForPage = () => {
      if (window.pageYOffset + window.innerHeight >= doc.scrollHeight - 100) {

        PageNext()
      }
    }
    document.addEventListener('scroll', ScrollForPage)
    return () => document.removeEventListener('scroll', ScrollForPage)
  }, [PageNext])
  React.useEffect(() => {
    if (statusTable === 'onlyInfo') {
      dispatch(statusLoadPage({ status: 'twoInOne' }))
    }
  }, [statusTable])

  return (
    <PageDashboard>
      {/* <CitiesRowFilters /> */}
      <TwoColsPage>
        <TableBox status={statusTable}>
          {citiesListState.map((item: Cities, index: number) => {
            const { results, next } = item
            return (
              <>
                <TableCols key={index}>
                  <CitiesTable windowWidth={windowWidth} citiesState={results ? results : []} loading={false} />
                </TableCols>
                {next && <Pagination status={statusTable} pagination={pagination ? pagination : []} activeCount={index} />}
              </>
            )
          })
          }
        </TableBox>
        <InfoCols status={statusTable} >
          <CitiesInfoBlock statusInfo={statusTable} />
        </InfoCols>
      </TwoColsPage>
    </PageDashboard>
  )
}