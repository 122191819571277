import { toNumber } from 'lodash';
import React from 'react'
import { useSelector } from 'react-redux'
import { InfoCols } from "../../common/InfoCols";
import { TwoColsPage } from "../../common/TwoColsPage";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { PageDashboard } from "../../navigation/PageDashboard";
import { RootState } from '../../store';
import { getPerformerById, getProfilesById } from '../../store/Performers/thunks';
import { PerformersInfoBlock } from './PerformersInfoBlock';

export default function PerformerInfo(props: any) {
  const dispatch = useAppDispatch()
  const item = useSelector((state: RootState) => state.performer.activeItem)

  React.useEffect(() => {
    console.log(item && item.id !== toNumber(props.match.params.performerid))
    if (!item || item && item.id !== toNumber(props.match.params.performerid)) {
      dispatch(getPerformerById(props.match.params.performerid))
      dispatch(getProfilesById(props.match.params.performerid))
    }
  }, [dispatch, item])
  return (
    <PageDashboard>
      <TwoColsPage>
        <InfoCols status={'onlyInfo'}>
          {
            item && <PerformersInfoBlock statusInfo={'onlyInfo'} />
          }
        </InfoCols>
      </TwoColsPage>
    </PageDashboard>
  )
}