import clsx from "clsx";
import {RouteComponentProps, withRouter} from "react-router-dom";
import React, {ReactNode} from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
  b2cPage: {
    "backgroundColor": "#EBF3F7",
    "flexDirection": "column",
    "width": "100%",
    "maxWidth": "680px",
    "margin": "auto",
    "paddingBottom": "100px",

    '&-enter': {
      "opacity": "0",
      "transform": "scale(0.9)"
    },

    '&-enter-active': {
      "opacity": "1",
      "transform": "translateX(0)",
      "transition": "opacity 200ms, transform 200ms",
      "position": "fixed",
      "top": "0",
      "bottom": "0",
      "left": "0",
      "right": "0"
    },

    '&-enter-done': {
      "opacity": "1"
    },

    '&-exit': {
      "opacity": "1",
    },

    '&-exit-active': {
      "opacity": "0",
      "transform": "scale(0.9)",
      "transition": "opacity 200ms, transform 200ms",
      "height": "0",
      "position": "fixed",
      "top": "0",
      "bottom": "0",
      "left": "0",
      "right": "0"
    },

    '&.b2c-page-100vh': {
      "height": "100vh",
      "paddingBottom": "0px",
    }
  }
});

interface PageProps extends RouteComponentProps<any> {
  children?: ReactNode,
  className?: string,
}

function Page({children, className}: PageProps) {
  const classes = useStyles();
  const cx = clsx([classes.b2cPage, className]);

  return (
    <section className={cx}>
      {children}
    </section>
  );
}

export default withRouter(Page);