import { TableCell, TableRow } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { withStyles } from '@material-ui/styles'
import React from 'react'

const SkeletonTableCell = withStyles({
  root: {
    borderRadius: '8px',
    border: 'none',
    whiteSpace: 'nowrap',
    height: 38,
    padding: 0,
  }
})(TableCell)
const NewSkeleton = withStyles({
  root: {
    '&::after': {
      background: 'linear-gradient(90deg, transparent, #CAE1ED, transparent)'
    }
  }
})(Skeleton)

export const SkeletTable = ({ windowWidth, tableRowProps, colSpan }: { windowWidth: number, tableRowProps: any, colSpan: number }) => {
  const countSkeleton = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const testReturn = countSkeleton.map((_, index) => <TableRow key={index} {...tableRowProps as any} >
    <SkeletonTableCell colSpan={colSpan} >
      <NewSkeleton animation="wave" height={'100%'} style={{ background: '#EBF3F7', borderRadius: 8 }} />
    </SkeletonTableCell>
  </TableRow>)
  return (
    // <TableRow {...tableRowProps as any} >
    //   <SkeletonTableCell colSpan={colSpan} >
    //     <Skeleton animation="wave" height={'100%'} />
    //   </SkeletonTableCell>
    // </TableRow>
    <>
      {testReturn}
    </>
  )

}
