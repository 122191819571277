
interface ListServicesProps {
  services: { title: string }[]
}

export const ListServices = ({ services }: ListServicesProps) => {
  const listShow = (arr: { title: string }[]) => {
    var str: String = ''
    arr.map((item: { title: String }) => {
      str += `${item.title},\u2009`
      return null
    })
    return str.replace(/^[,\s]+|[,\s]+$/g, '')
  }
  return (
    <span>
      {
        `${services.length}:\u2009`
      }
      {
        listShow(services)
      }
    </span>
  )
}