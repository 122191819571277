import React from "react"
import {
  makeStyles,
} from "@material-ui/core"
import { CommonTable } from "../../components/Table/CommonDataTable/CommonTable"
import { DataTable } from "../../components/Table/CommonDataTable/DataTable"
import { CommonTableRow, CommonTableRowProps } from "../../components/Table/CommonDataTable/CommonTableRow"
import { CommonTableCell, CommonTableCellProps } from "../../components/Table/CommonDataTable/CommonTableCell"
import { CommonTableHeadRow, CommonTableHeadRowMobile } from "../../components/Table/CommonDataTable/CommonTableHeadRow"
import { CommonTableHeadCell } from "../../components/Table/CommonDataTable/CommonTableHeadCell"
import { CommonTableHead } from "../../components/Table/CommonDataTable/CommonTableHead"
import { useAppDispatch } from "../../hooks/useAppDispatch"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import { useSpec } from './spec';
import { CammonTableRowMobile } from './../../components/Table/CommonDataTable/CommonTableRow';
import { Performer } from '../../entities/Performer'
import { useHistory } from "react-router-dom"
import { backPerformer, closeInfoPerformer, nextPerformer, openInfoPage, openInfoPerformer } from "../../store/Performers/performerStore"
import { getProfilesById } from "../../store/Performers/thunks"


const TableMobileStyle = makeStyles({
  mediaCities: {
    '@media screen and (max-width:1200px)': {
      '& tr': {
        display: 'grid',
        gridTemplateRows: "repeat(2, 30px)",
      }
    }
  }
})

export const PerformancesTable = ({ windowWidth, performerState, loading }: { windowWidth: number, performerState: Performer[], loading: boolean }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { activeItemIndex, activeItemId, status, activeUserLoading, activeUser } = useSelector((state: RootState) => state.performer)
  const columnStatus = React.useMemo(() => {
    if (status === 'onlyTable') {
      return false
    }
    if (status === 'twoInOne') {
      return true
    }
  }, [status])

  const onClickItem = React.useCallback((item: Performer, index: number) => {
    if (!activeUserLoading && activeUser?.id !== item.id) {
      dispatch(getProfilesById(item.id))
      dispatch(openInfoPerformer({ item: item, index: index }))
    }
  }, [dispatch,
    activeUserLoading,
    getProfilesById,
    openInfoPerformer])

  const onClickItemMobile = (activeItemId: string) => {
    history.push(`/performers/${activeItemId}`);
  }
  const { spec, specMobile } = useSpec({ onClickItem, onClickItemMobile })

  // new func. keyMap
  const closeInfo = React.useCallback(() => {
    dispatch(closeInfoPerformer())
  }, [dispatch])

  const nextIndexItem = React.useCallback(() => {
    dispatch(nextPerformer())
  }, [dispatch])

  const downIndexItem = React.useCallback(() => {
    dispatch(backPerformer())
  }, [dispatch])

  const openFullInfo = React.useCallback(() => {
    // if (columnStatus) {
    dispatch(openInfoPage())
    history.push(`/performers/${activeItemId}`);
    // }
  }, [dispatch,
    activeItemId,
    history])

  React.useEffect(() => {
    const onKeyDown = (event: any) => {
      if (event.target.nodeName === "BODY") {
        if (event.key === "Escape") {
          closeInfo()
        }
        if (activeItemIndex !== undefined) {
          if (event.keyCode === 188) {
            downIndexItem()
          }
          if (event.keyCode === 190) {
            nextIndexItem()
          }
        }
        if (event.keyCode === 70) {
          openFullInfo()
        }
      }
    }
    document.addEventListener('keydown', onKeyDown)

    return () => document.removeEventListener('keydown', onKeyDown)
  }, [closeInfo,
    downIndexItem,
    nextIndexItem,
    openFullInfo,
    activeItemIndex])
  // new func. keyMap ****

  const checkWidth = React.useMemo(() => {
    if (windowWidth <= 1200) {
      return specMobile
    }
    if (windowWidth > 1200) {
      return spec
    }
    return spec
  }, [windowWidth, columnStatus, spec, specMobile])

  const Row = React.useMemo(() => {
    if (windowWidth <= 1200) {
      return CammonTableRowMobile
    }
    if (windowWidth > 1200) {
      return CommonTableRow
    }
    return CommonTableRow
  }, [windowWidth])

  const HeadRow = React.useMemo(() => {
    if (windowWidth <= 1200) {
      return CommonTableHeadRowMobile
    }
    if (windowWidth > 1200) {
      return CommonTableHeadRow
    }
    return CommonTableHeadRow
  }, [windowWidth])

  return (
    <DataTable<
      Performer,
      React.ComponentProps<typeof CommonTable>,
      CommonTableRowProps<Performer>,
      CommonTableCellProps<Performer>,
      React.ComponentProps<typeof CommonTableHead>,
      React.ComponentProps<typeof CommonTableHeadRow>,
      React.ComponentProps<typeof CommonTableHeadCell>
    >
      data={performerState as Performer[]}
      Table={CommonTable}
      tableProps={{ className: TableMobileStyle().mediaCities }}
      TableRow={Row}
      TableCell={CommonTableCell}
      TableHead={CommonTableHead}
      TableHeadRow={HeadRow}
      TableHeadCell={CommonTableHeadCell}
      spec={checkWidth}
      itemKey={(performer: Performer) => performer.id}
      activeItem={activeItemId}
      loading={loading}
    />
  );
}