import { InfoCols } from "../../common/InfoCols"
import { TwoColsPage } from "../../common/TwoColsPage"
import { TableCols } from "../../components/Table/TableCols"
import { PageDashboard } from "../../navigation/PageDashboard"
import { EventTable } from "./EventsTable"
import { useSelector } from 'react-redux'
import { RootState } from "../../store"
import React from 'react'

export default function Events() {
  const status = useSelector((state: RootState) => state.table.Events.status)
  const WW = React.useMemo(() => {
    return window.innerWidth
  }, [window.innerWidth])
  const [windowWidth, setWindowWidth] = React.useState(WW)
  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.onload = handleResize

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize ', handleResize);
  }, [])
  return (
    <PageDashboard>
      <TwoColsPage>
        <TableCols status={status}>
          <EventTable windowWidth={windowWidth} />
        </TableCols>
        <InfoCols status={status}>

        </InfoCols>
      </TwoColsPage>
    </PageDashboard>
  )
}