import React from "react"

import { CommonTable } from "../../components/Table/CommonDataTable/CommonTable"
import { DataTable } from "../../components/Table/CommonDataTable/DataTable"
import { CommonTableRow, CommonTableRowProps } from "../../components/Table/CommonDataTable/CommonTableRow"
import { CommonTableCell, CommonTableCellProps } from "../../components/Table/CommonDataTable/CommonTableCell"
import { CommonTableHeadRow, CommonTableHeadRowMobile } from "../../components/Table/CommonDataTable/CommonTableHeadRow"
import { CommonTableHeadCell } from "../../components/Table/CommonDataTable/CommonTableHeadCell"
import { CommonTableHead } from "../../components/Table/CommonDataTable/CommonTableHead"
import { useAppDispatch } from './../../hooks/useAppDispatch'
import { useSelector } from 'react-redux'
import { RootState } from "../../store"
import { setCitiesStatus } from "../../store/table/tableStore"
import { useHistory } from "react-router"

import { useSpec } from '../Cities/spec'
import { CammonTableRowMobile } from './../../components/Table/CommonDataTable/CommonTableRow'
import { makeStyles } from '@material-ui/core'
import { City } from "../../entities/City"
import { backItem, closeInfoTable, nextItem, openInfoPage, openInfoTable } from "../../store/City/cityStore"

const TableMobileStyle = makeStyles({
  mediaCities: {
    '@media screen and (max-width:1200px)': {
      '& tr': {
        display: 'grid',
        gridTemplateRows: "repeat(2, 30px)",
      }
    }
  },
  headStyle: {
    '& th': {
      position: 'sticky',
      top: 0,
      background: 'white'
    }
  }
})

export const CitiesTable = ({ windowWidth, citiesState, loading }: { windowWidth: number, citiesState: City[], loading?: boolean }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const status = useSelector((state: RootState) => state.city.status)
  const activeItem = useSelector((state: RootState) => state.city.activeItemId)
  const activeItemIndex = useSelector((state: RootState) => state.city.activeItemIndex)
  const columnStatus = React.useMemo(() => {
    if (status === 'onlyTable') {
      return false
    }
    if (status === 'twoInOne') {
      return true
    }
  }, [status])
  // new func. keyMap
  const closeInfo = React.useCallback(() => {
    dispatch(closeInfoTable())
  }, [dispatch])

  const nextIndexItem = React.useCallback(() => {
    dispatch(nextItem())
  }, [dispatch])

  const downIndexItem = React.useCallback(() => {
    dispatch(backItem())
  }, [dispatch])

  const openFullInfo = React.useCallback(() => {
    // if (columnStatus) {
    dispatch(openInfoPage())
    history.push(`/cities/${activeItem}`);
    // }
  }, [dispatch,
    openInfoPage,
    activeItem,
    activeItemIndex,
    history])

  React.useEffect(() => {
    const onKeyDown = (event: any) => {
      if (event.target.nodeName === "BODY") {
        if (event.key === "Escape") {
          closeInfo()
        }
        if (activeItemIndex !== undefined) {
          if (event.keyCode === 188) {
            downIndexItem()
          }
          if (event.keyCode === 190) {
            nextIndexItem()
          }
        }
        if (event.keyCode === 70) {
          openFullInfo()
        }
      }
    }
    document.addEventListener('keydown', onKeyDown)

    return () => document.removeEventListener('keydown', onKeyDown)
  }, [closeInfo,
    downIndexItem,
    nextIndexItem,
    openFullInfo,
    activeItemIndex])
  // new func. keyMap ****

  const onClickItem = (item: City, index: number) => {
    dispatch(openInfoTable({ item, index }))
  }

  const onClickItemMobile = (id: string) => {
    history.push(`/cities/${id}`)
  }

  const { spec, specMobile } = useSpec({ onClickItem, onClickItemMobile })

  const checkWidth = React.useMemo(() => {
    if (windowWidth <= 1200) {
      // dispatch(setCitiesStatus({ status: 'onlyTable', id: 0 })) // спорное решение - надо тестировать
      return specMobile
    }
    if (windowWidth > 1200) {
      return spec
    }
    return spec
  }, [windowWidth, columnStatus, spec, specMobile])

  const Row = React.useMemo(() => {
    if (windowWidth <= 1200) {
      return CammonTableRowMobile
    }
    if (windowWidth > 1200) {
      return CommonTableRow
    }
    return CommonTableRow
  }, [windowWidth])

  const HeadRow = React.useMemo(() => {
    if (windowWidth <= 1200) {
      return CommonTableHeadRowMobile
    }
    if (windowWidth > 1200) {
      return CommonTableHeadRow
    }
    return CommonTableHeadRow
  }, [windowWidth])

  return (
    <DataTable<
      City,
      React.ComponentProps<typeof CommonTable>,
      CommonTableRowProps<City>,
      CommonTableCellProps<City>,
      React.ComponentProps<typeof CommonTableHead>,
      React.ComponentProps<typeof CommonTableHeadRow>,
      React.ComponentProps<typeof CommonTableHeadCell>
    >
      data={citiesState as City[]}
      Table={CommonTable}
      TableRow={Row}
      tableProps={{ className: TableMobileStyle().mediaCities }}
      TableCell={CommonTableCell}
      TableHead={CommonTableHead}
      TableHeadRow={HeadRow}
      TableHeadCell={CommonTableHeadCell}
      tableHeadRowStyle={TableMobileStyle().headStyle}
      spec={checkWidth}
      itemKey={(city: City) => city.id}
      activeItem={activeItem}
      loading={loading}
    />
  )
}
