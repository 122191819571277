export const StarSvg = () => {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.7329 0.899902C8.00361 0.899902 8.25005 1.05599 8.36575 1.30073L10.2218 5.22701L14.4919 5.79518C14.7586 5.83067 14.9813 6.01587 15.0649 6.27161C15.1485 6.52734 15.0781 6.80835 14.8839 6.99449L11.7561 9.99151L12.5416 14.2736C12.5904 14.5392 12.4819 14.8092 12.263 14.9674C12.044 15.1255 11.7537 15.1436 11.5168 15.0138L7.7329 12.9408L3.949 15.0138C3.71214 15.1436 3.42175 15.1255 3.20281 14.9674C2.98387 14.8092 2.87543 14.5392 2.92416 14.2736L3.70969 9.99151L0.581933 6.99449C0.387671 6.80835 0.317285 6.52734 0.400868 6.27161C0.48445 6.01587 0.707208 5.83067 0.973905 5.79518L5.24398 5.22701L7.10005 1.30073C7.21574 1.05599 7.46219 0.899902 7.7329 0.899902ZM7.7329 3.23778L6.34684 6.16981C6.24531 6.38458 6.04179 6.5332 5.80631 6.56453L2.60175 6.99092L4.95053 9.24153C5.12048 9.40438 5.19721 9.64174 5.15474 9.87326L4.56665 13.0791L7.39657 11.5287C7.6061 11.4139 7.8597 11.4139 8.06923 11.5287L10.8991 13.0791L10.3111 9.87326C10.2686 9.64174 10.3453 9.40438 10.5153 9.24153L12.864 6.99092L9.65948 6.56453C9.424 6.5332 9.22048 6.38458 9.11896 6.16981L7.7329 3.23778Z" fill="#74737B" />
  </svg>
}
export const ThreePointSvg = () => {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.0002" cy="12.9357" r="0.7" transform="rotate(-180 8.0002 12.9357)" fill="#74737B" stroke="#74737B" strokeWidth="1.4" />
    <circle cx="8.0002" cy="7.99971" r="0.7" transform="rotate(-180 8.0002 7.99971)" fill="#74737B" stroke="#74737B" strokeWidth="1.4" />
    <circle cx="8.0002" cy="3.06416" r="0.7" transform="rotate(-180 8.0002 3.06416)" fill="#74737B" stroke="#74737B" strokeWidth="1.4" />
  </svg>
}
export const ArrowSvg = () => {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.8283 8.49483L6.82833 12.4948L5.83838 11.5049L9.3434 7.99986L5.83838 4.49483L6.82833 3.50488L10.8283 7.50488C11.1017 7.77825 11.1017 8.22147 10.8283 8.49483Z" fill="#CAE1ED" />
  </svg>
}