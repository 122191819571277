import Page from '../../navigation/Page'
import { makeStyles } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'

import './page.css'

const useStyles = makeStyles({
  b2cDashboardPage: {
    backgroundColor: ' #ebf3f7',
    flexDirection: 'column',
    overflowY: 'auto',
    width: '100%',
    maxWidth: '100%',
    paddingBottom: '10px!important',
    marginTop: '54px'
  }
})

type PageDashboardProps = React.ComponentProps<typeof Page>

export const PageDashboard = ({ children, className, ...props }: PageDashboardProps) => {
  const classes = useStyles();
  return (
    <Page className={clsx(classes.b2cDashboardPage, className)} {...props}>
      {children}
    </Page>
  )
}