import React from 'react'
import { ColumnSpec } from "../../components/Table/CommonDataTable/DataTable"
import { useStyle } from "../../components/Table/useStyles"
import { TableCellProps } from '@material-ui/core';
import { CommonTableHeadCell } from './../../components/Table/CommonDataTable/CommonTableHeadCell';
import { ContainerSpan } from './../../components/Table/Common/ContainerSpan';
import clsx from 'clsx';
import { ThreePointSvg } from "../../svg"
import { useSelector } from 'react-redux'
import { RootState } from "../../store"
import { Service } from '../../entities/Service'
import { TagsList } from './../../components/Table/Common/TagList';
import { RateColorStyle } from './../../components/Table/Common/RateColorStyle';
import { NumberDigits } from "../../utils/NumberRender"
import { ReactComponent as ArrowLeft } from '../../../icons/icon-arrow-left.svg'

const RateOprion = {
  minimum: 50,
  average: 70,
  maximum: 90,
}
export const useSpec = ({ onClickItem, onClickItemMobile }: { onClickItem?: any, onClickItemMobile?: any }) => {
  const classes = useStyle()
  const status = useSelector((state: RootState) => state.table.Services.status)
  const columnStatus = React.useMemo(() => {
    if (status === 'onlyTable') {
      return false
    }
    if (status === 'twoInOne') {
      return true
    }
  }, [status])
  const spec: ColumnSpec<Service, TableCellProps, React.ComponentProps<typeof CommonTableHeadCell>>[] = [
    {
      colId: 'title',
      visible: true,
      HeadCellContentRender: () => <span>Название услуги</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>{item.service}</ContainerSpan>,
      tableCellProps: { className: classes.textBold, style: { width: 355 } }
    },
    {
      colId: 'specialization',
      visible: true,
      HeadCellContentRender: () => <span>Специализация</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>{item.specialization}</ContainerSpan>,
      tableCellProps: { style: { width: 185 } }
    },
    {
      colId: 'category',
      visible: true,
      HeadCellContentRender: () => <span>Категория</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>{item.category}</ContainerSpan>,
      tableCellProps: { style: { width: 140 } }
    },
    {
      colId: 'mastersCount',
      visible: true,
      HeadCellContentRender: () => <span>Исполн.</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>{NumberDigits(item.workersCount)}</ContainerSpan>,
      tableCellProps: { className: clsx(classes.textBold, classes.textRight), style: { width: 68 } }
    },
    {
      colId: 'rate',
      visible: true,
      HeadCellContentRender: () => <span>Рейт.</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}> <RateColorStyle rate={item.rating ? item.rating : 0} option={RateOprion} /></ContainerSpan>,
      tableCellProps: { className: clsx(classes.textBold, classes.textRight), style: { width: 44 } }
    },
    {
      colId: 'queries',
      visible: !columnStatus,
      HeadCellContentRender: () => <span>Запросы</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}> <RateColorStyle rate={0} option={RateOprion} /></ContainerSpan>,
      tableCellProps: { className: clsx(classes.textBold, classes.textRight), style: { width: 75 } }
    },
    {
      colId: 'tags',
      visible: !columnStatus,
      HeadCellContentRender: () => <span>Теги</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}><TagsList tags={item.tags} /></ContainerSpan>,
      tableCellProps: { className: classes.overflow }
    },
    {
      colId: 'ids',
      visible: true,
      HeadCellContentRender: () => <span>#id</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>{`#${item.id}`}</ContainerSpan>,
      tableCellProps: { className: clsx(classes.textLightGray, classes.textRight, !columnStatus && 'gradientBefore'), style: { width: 80 } }
    },
    {
      colId: 'button',
      visible: !columnStatus,
      HeadCellContentRender: () => <span></span>,
      tableHeadCellProps: { style: { width: 32 } },
      CellContentRender: () => <ContainerSpan ><ThreePointSvg /></ContainerSpan>,
      tableCellProps: {
        style: {
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          width: 32
        }
      }
    }
  ]
  const specMobile: ColumnSpec<Service, TableCellProps, React.ComponentProps<typeof CommonTableHeadCell>>[] = [
    {
      colId: 'title',
      visible: true,
      HeadCellContentRender: () => <span>Название</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={clsx(classes.textBold, classes.textColorB)}>{item.service}</ContainerSpan>,
      tableCellProps: { style: { borderRadius: '8px 8px 0px 0px' }, }
    },
    {
      colId: 'category',
      visible: true,
      HeadCellContentRender: () => <>
        <span style={{ fontWeight: 400 }}>Сцепиализация</span>
        <span style={{ display: 'flex', alignItems: 'center', marginTop: 2 }}><ArrowLeft /></span>
        <span className={classes.textLight}>Категория</span>
      </>,
      tableHeadCellProps: {
        style: {
          display: 'flex'
        }
      },
      CellContentRender: ({ item }) => <>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={clsx(classes.textLight, classes.textColorB)}>{item.specialization}</ContainerSpan>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} style={{ display: 'flex', alignItems: 'center', marginTop: 2 }} ><ArrowLeft className={'arrow'} /></ContainerSpan>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)}>{item.category}</ContainerSpan>
      </>,
      tableCellProps: {
        style: {
          display: 'flex'
        }
      }
    },
    {
      colId: 'rate',
      visible: true,
      HeadCellContentRender: () => <>
        <span>Рейтинг</span>
        <span style={{ marginLeft: 8 }}>Исполнителей</span>
        <span className={classes.textLightGray} style={{ marginLeft: 'auto' }}>#id</span>
      </>,
      tableHeadCellProps: {
        style: {
          display: 'flex'
        }
      },
      CellContentRender: ({ item }) => <>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)}><RateColorStyle rate={item.rating ? item.rating : 0} option={RateOprion} title={'Рейт.'} /></ContainerSpan>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} style={{ marginLeft: 12 }}><span>Исполн. </span>{NumberDigits(item.workersCount)}</ContainerSpan>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={clsx('elemCell gradientBefore', classes.textLightGray)} style={{ marginLeft: 'auto', position: 'relative' }}>{`#${item.id}`}</ContainerSpan>
      </>,
      tableCellProps: {
        style: {
          borderRadius: '0px 0px 8px 8px',
          display: 'flex'
        }
      }
    },
  ]
  return { spec, specMobile }
}