import React, { ReactNode } from 'react'
import { Box, withStyles } from "@material-ui/core";

const TabelRowInfo = withStyles({
    root: {
        fontSize: '16px'
    }
})(Box)

interface tableRowInfoProps {
    children: ReactNode
}

export const TableRowInfo = ({ children }: tableRowInfoProps) => {
    return (
        <TabelRowInfo>
            {children}
        </TabelRowInfo >
    )
}
