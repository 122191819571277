import { TableCellProps } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { ReactComponent as RateIcon } from '../../../icons/icon-rate.svg'
import { ReactComponent as ArrowRightIcon } from '../../../icons/icon-arrowRight.svg'
import { useSelector } from 'react-redux'
import { FullName } from '../../components/FullName'
import { PointAndText } from '../../components/PointAndText'
import { ContainerSpan } from '../../components/Table/Common/ContainerSpan'
import { DateView } from '../../components/Table/Common/DateView'
import { ListServices } from '../../components/Table/Common/ListServices'
import { RateColorStyle } from '../../components/Table/Common/RateColorStyle'
import { CommonTableHeadCell } from '../../components/Table/CommonDataTable/CommonTableHeadCell'
import { ColumnSpec } from '../../components/Table/CommonDataTable/DataTable'
import { useStyle } from '../../components/Table/useStyles'
import { RootState } from '../../store'
import { ThreePointSvg } from '../../svg'
import { PriceRender } from '../../utils/NumberRender'
import { Order } from '../../entities/Order'


export const useSpec = ({ onClickItem, onClickItemMobile }: { onClickItem: any, onClickItemMobile?: any }) => {
  const classes = useStyle()
  const status = useSelector((state: RootState) => state.table.Orders.status)
  const columnStatus = React.useMemo(() => {
    if (status === 'onlyTable') {
      return false
    }
    if (status === 'twoInOne') {
      return true
    }
  }, [status])
  const spec: ColumnSpec<Order, TableCellProps, React.ComponentProps<typeof CommonTableHeadCell>>[] = [
    {
      colId: "status",
      visible: true,
      HeadCellContentRender: () => { return !columnStatus ? <PointAndText view={true} value={'default'} /> : <PointAndText view={true} /> },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)} className="elemOrderCell"><PointAndText view={!columnStatus} value={item.status} /></ContainerSpan>,
      tableCellProps: { className: clsx(classes.textBold, !columnStatus ? classes.statusBarFull : classes.statusBarSmall) }
    },
    {
      colId: "services",
      visible: true,
      HeadCellContentRender: () => <span>Услуги</span>,
      tableHeadCellProps: { className: classes.maxWidth },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}><ListServices services={item.services} /></ContainerSpan>,
      tableCellProps: { className: clsx(classes.maxWidth) }
    },
    {
      colId: "employeer",
      visible: true,
      HeadCellContentRender: () => <span>Исполнитель</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}><FullName data={{ firstname: item.worker.firstName, lastname: item.worker.lastName, patronymic: item.worker.patronymic }} /></ContainerSpan>,
      tableCellProps: { className: clsx(classes.maxWidth, 'gradientBefore', classes.textBold) }
    },
    {
      colId: "arrow",
      visible: !columnStatus,
      HeadCellContentRender: () => <span></span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}><ArrowRightIcon className={'arrow'} /></ContainerSpan>,
      tableCellProps: { className: 'gradientBefore', style: { width: 16 } }
    },
    {
      colId: "client",
      visible: !columnStatus,
      HeadCellContentRender: () => <span>Клиент</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>
        {item.client ? <FullName data={{ firstname: item.client.firstName, lastname: item.client.lastName, patronymic: '' }} />
          : '-'}</ContainerSpan>,
    },
    {
      colId: "price",
      visible: true,
      HeadCellContentRender: () => <span>Цена</span>,
      tableHeadCellProps: { className: clsx(classes.pricePos) },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)} className='elemCell gradientBefore'>{PriceRender(item.price)}</ContainerSpan>,
      tableCellProps: { className: clsx(classes.pricePos, classes.textBold) }
    },
    {
      colId: "createDate",
      visible: !columnStatus,
      HeadCellContentRender: () => <span>Создано</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}><DateView date={item.createdAt} /></ContainerSpan>,
      tableCellProps: { className: classes.textLight, style: { width: 132 } }
    },
    {
      colId: "date",
      visible: true,
      HeadCellContentRender: () => <span>Изменено</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}><DateView date={item.modifiedAt} /></ContainerSpan>,
      tableCellProps: { style: { width: 132 } }
    },
    {
      colId: "source",
      visible: true,
      HeadCellContentRender: () => <span>Источник</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>{item.source}</ContainerSpan>,
      tableCellProps: { className: classes.textBold, style: { width: 102 } }
    },
    {
      colId: "rate",
      visible: true,
      HeadCellContentRender: () => <RateIcon />,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}><RateColorStyle rate={item.rating ? item.rating : 0} option={{ minimum: 2, average: 4, maximum: 5 }} /></ContainerSpan>,
      tableCellProps: { style: { width: 16 } }
    },
    {
      colId: 'ids',
      visible: !columnStatus,
      HeadCellContentRender: () => <span>#id</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>{`#${item.id}`}</ContainerSpan>,
      tableCellProps: { className: clsx(classes.textLightGray, classes.textRight, !columnStatus && 'gradientBefore'), style: { width: 80 } }
    },
    {
      colId: 'button',
      visible: !columnStatus,
      HeadCellContentRender: () => <span></span>,
      tableHeadCellProps: { style: { width: 32 } },
      CellContentRender: () => <ContainerSpan><ThreePointSvg /></ContainerSpan>,
      tableCellProps: {
        style: {
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          width: 32
        }
      }
    }
  ]
  const specMobile: ColumnSpec<Order, TableCellProps, React.ComponentProps<typeof CommonTableHeadCell>>[] = [
    {
      colId: "services",
      visible: true,
      HeadCellContentRender: () => <><span className={classes.textBold}>Услуги</span><span className={classes.textLight} style={{ marginRight: 8 }}>Цена</span></>,
      tableHeadCellProps: {
        style: {
          fontWeight: 400,
          display: 'flex',
          justifyContent: 'space-between'
        }
      },
      CellContentRender: ({ item }) => <>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={clsx(classes.serviceMobile, 'elemCell', classes.textBold)}><ListServices services={item.services} /></ContainerSpan>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={clsx('elemCell gradientBefore', classes.textLight)} style={{ position: 'relative', paddingRight: 8, right: -8 }}>{PriceRender(item.price)}</ContainerSpan>
      </>,
      tableCellProps: {
        style: {
          borderRadius: '8px 8px 0px 0px',
          display: 'flex',
          justifyContent: 'space-between'
        }
      }
    },
    {
      colId: "employeer",
      visible: true,
      HeadCellContentRender: () => <><span className={classes.textBold} style={{ width: '188px' }}>Исполнитель</span>
        <span style={{ margin: '2px 2px 0px 2px', display: 'flex', alignItems: 'center' }}><ArrowRightIcon /></span>
        <span style={{ fontWeight: 400 }}>Клиент</span>
      </>,
      tableHeadCellProps: {
        style: {
          display: 'flex'
        }
      },
      CellContentRender: ({ item }) => <>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={clsx(classes.textBold, classes.textColorGG, classes.nameMobile)}><FullName data={{ firstname: item.worker.firstName, lastname: item.worker.lastName, patronymic: item.worker.patronymic }} /></ContainerSpan>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={clsx(classes.arrowNameMobile, 'mobileBg', 'gradientBefore')} style={{ display: 'flex', alignItems: 'center', padding: '0px 2px', marginTop: 2 }} ><ArrowRightIcon className={'arrow'} /></ContainerSpan>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={clsx(classes.postArowwMobile, 'mobileBg', classes.textColorFG)}><FullName data={{ firstname: item.client.firstName, lastname: item.client.lastName, patronymic: '' }} /></ContainerSpan>
      </>,
      tableCellProps: {
        className: 'gradientAfter',
        style: {
          display: 'flex'
        }
      }
    },
    {
      colId: "createDate",
      visible: !columnStatus,
      HeadCellContentRender: () => <><span className={classes.textBold}>Создано</span><span style={{ marginLeft: 8, fontWeight: 400 }}>Изменено</span></>,
      tableHeadCellProps: {
        style: {
          display: 'flex'
        }
      },
      CellContentRender: ({ item }) => <>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)}> <span style={{ marginRight: 6 }}>Созд.</span> <DateView date={item.createdAt} /></ContainerSpan>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={classes.textLight}><span style={{ marginRight: 6, marginLeft: 18 }}>Изм.</span><DateView date={item.modifiedAt} /></ContainerSpan>
      </>,
      tableCellProps: {
        className: 'gradientAfter',
        style: {
          display: 'flex',

        }
      }
    },
    {
      colId: "fourinone",
      visible: true,
      HeadCellContentRender: () => <><span style={{ marginLeft: '-8px' }} className={classes.textBold} ><PointAndText view={true} value={'default'} /></span>
        <span style={{ padding: '0px 8px 0px 8px', marginTop: 2 }}> <RateIcon /></span>
        <span style={{ fontWeight: 400 }}>Источник</span>
        <span className={classes.textLightGray} style={{ marginLeft: 'auto' }}>#id</span>
      </>,
      tableHeadCellProps: {
        style: {
          display: 'flex'
        }
      },
      CellContentRender: ({ item }) => <>
        <ContainerSpan style={{ marginLeft: '-8px' }} onClick={() => onClickItemMobile(item.id)} className={clsx("elemOrderCell", classes.textBold,)}><PointAndText view={true} value={item.status} /></ContainerSpan>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} style={{ padding: '0px 8px 0px 6px' }}>
          <RateColorStyle rate={item.rating ? item.rating : 0} option={{ minimum: 2, average: 4, maximum: 5 }} />
          <RateIcon style={{ marginBottom: '-2px' }} />
          {/* clsx(item.rating <= 2 && classes.RateLow, item.rating > 2 && item.rating <= 4 && classes.RateMiddle, item.rating > 4 && item.rate >= 5 && classes.RateHigh) */}
        </ContainerSpan>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={classes.textColorGG}>{item.source} </ContainerSpan>
        <ContainerSpan style={{ marginLeft: 'auto', position: 'relative' }} onClick={() => onClickItemMobile(item.id)} className={clsx(classes.textLightGray, 'gradientBefore')}>{`#${item.id}`}</ContainerSpan>
        <ContainerSpan style={{
          marginLeft: 5,
          display: 'flex',
          alignItems: ' center'
        }}><ThreePointSvg /></ContainerSpan>
      </>,
      tableCellProps: {
        style: {
          display: 'flex',
          borderRadius: '0px 0px 8px 8px'
        }
      }
    }
  ]
  return { spec, specMobile }
}