import React from 'react'
import { DashboardRouter } from './navigation/DashboardRouter'

import { ThemeProvider, CssBaseline, Fade } from '@material-ui/core'
import { b2cTheme, useGlobalDashboardStyles } from "../theme/B2CTheme"

import { Provider } from 'react-redux'
import store from "./store"

import '../utils/validation'

const RouterOnAuth = () => {
  const userProfile = true

  return (
    <>
      <Fade mountOnEnter={true} unmountOnExit={true} in={userProfile}>
        <div>
          {/*TODO: Admin profile context provider*/}
          <DashboardRouter />
        </div>
      </Fade>
      <Fade mountOnEnter={true} unmountOnExit={true} in={!userProfile}>
        <div>
          Надо сделать авторизацию
          {/*<AuthWizard/>*/}
        </div>
      </Fade>
    </>
  )
}

export const DashboardApp = () => {
  useGlobalDashboardStyles()
  return (
    // <YMaps query={{load: 'Map,Placemark,Polygon,geoObject.addon.editor'}}>
    <ThemeProvider theme={b2cTheme}>
      <CssBaseline />
      <Provider store={store}>
        <RouterOnAuth />
      </Provider>
    </ThemeProvider>
    // </YMaps>
  )
}
