import React from "react"
import {
  makeStyles
} from "@material-ui/core"
import { CommonTable } from "../../components/Table/CommonDataTable/CommonTable"
import { DataTable } from "../../components/Table/CommonDataTable/DataTable"
import { CommonTableRow, CommonTableRowProps } from "../../components/Table/CommonDataTable/CommonTableRow"
import { CommonTableCell, CommonTableCellProps } from "../../components/Table/CommonDataTable/CommonTableCell"
import { CommonTableHeadRow, CommonTableHeadRowMobile } from "../../components/Table/CommonDataTable/CommonTableHeadRow"
import { CommonTableHeadCell } from "../../components/Table/CommonDataTable/CommonTableHeadCell"
import { CommonTableHead } from "../../components/Table/CommonDataTable/CommonTableHead"
import { useAppDispatch } from "../../hooks/useAppDispatch"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import { selectServices, setServicesStatus } from "../../store/table/tableStore"
import { useSpec } from './spec';
import { CammonTableRowMobile } from './../../components/Table/CommonDataTable/CommonTableRow';

import { useAppSelector } from "../../hooks/useAppSelector"
import { Service } from "../../entities/Service"

// export interface Services {
//   name: string,
//   specialization: string,
//   category: string,
//   mastersCount: number,
//   rate: number,
//   queries: number,
//   id: string,
//   tags: { title: string; count: number; }[],
// }
const TableMobileStyle = makeStyles({
  mediaCities: {
    '@media screen and (max-width:1200px)': {
      '& tr': {
        display: 'grid',
        gridTemplateRows: "repeat(3, 30px)",
      }
    }
  }
})
export const ServicesTable = ({ windowWidth }: { windowWidth: number }) => {
  const dispatch = useAppDispatch()
  const servicesState = useAppSelector(selectServices)

  const status = useSelector((state: RootState) => state.table.Services.status)
  const columnStatus = React.useMemo(() => {
    if (status === 'onlyTable') {
      return false
    }
    if (status === 'twoInOne') {
      return true
    }
  }, [status])
  const onClickItem = (item: string) => {
    dispatch(setServicesStatus({ status: 'twoInOne', id: item }))
  }
  const onClickItemMobile = (activeItemId: string) => {
    alert(`page "services/${activeItemId}" in developmen`)
  }
  const { spec, specMobile } = useSpec({ onClickItem, onClickItemMobile })

  const checkWidth = React.useMemo(() => {
    if (windowWidth <= 1200) {
      return specMobile
    }
    if (windowWidth > 1200) {
      return spec
    }
    return spec
  }, [windowWidth, columnStatus, spec, specMobile])

  const Row = React.useMemo(() => {
    if (windowWidth <= 1200) {
      return CammonTableRowMobile
    }
    if (windowWidth > 1200) {
      return CommonTableRow
    }
    return CommonTableRow
  }, [windowWidth])

  const HeadRow = React.useMemo(() => {
    if (windowWidth <= 1200) {
      return CommonTableHeadRowMobile
    }
    if (windowWidth > 1200) {
      return CommonTableHeadRow
    }
    return CommonTableHeadRow
  }, [windowWidth])

  return (
    <DataTable<
      Service,
      React.ComponentProps<typeof CommonTable>,
      CommonTableRowProps<Service>,
      CommonTableCellProps<Service>,
      React.ComponentProps<typeof CommonTableHead>,
      React.ComponentProps<typeof CommonTableHeadRow>,
      React.ComponentProps<typeof CommonTableHeadCell>
    >
      data={servicesState?.results as Service[]}
      Table={CommonTable}
      tableProps={{ className: TableMobileStyle().mediaCities }}
      TableRow={Row}
      // tableRowProps={}
      TableCell={CommonTableCell}
      TableHead={CommonTableHead}
      TableHeadRow={HeadRow}
      TableHeadCell={CommonTableHeadCell}
      spec={checkWidth}
      itemKey={(city: Service) => city.id}
    />
  )
}