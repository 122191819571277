import React from "react"
import {
  TableCellProps,
} from "@material-ui/core"
import { CommonTable } from "../../components/Table/CommonDataTable/CommonTable"
import { ColumnSpec, DataTable } from "../../components/Table/CommonDataTable/DataTable"
import { CommonTableRow, CommonTableRowProps } from "../../components/Table/CommonDataTable/CommonTableRow"
import { CommonTableCell, CommonTableCellProps } from "../../components/Table/CommonDataTable/CommonTableCell"
import { CommonTableHeadRow } from "../../components/Table/CommonDataTable/CommonTableHeadRow"
import { CommonTableHeadCell } from "../../components/Table/CommonDataTable/CommonTableHeadCell"
import { CommonTableHead } from "../../components/Table/CommonDataTable/CommonTableHead"
import clsx from "clsx";

import { ThreePointSvg } from "../../svg"
import { TagsList } from "../../components/Table/Common/TagList"
import { RateColorStyle } from "../../components/Table/Common/RateColorStyle"
import { useStyle } from "../../components/Table/useStyles"

import { ContainerSpan } from "../../components/Table/Common/ContainerSpan"
import { Service } from "../../entities/Service"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
const RateOprion = {
  minimum: 50,
  average: 70,
  maximum: 90,
}

export const TabsServices = () => {
  const { status, servicesForCity } = useSelector((state: RootState) => state.city)

  // const cityState = useSelector((state: RootState) => state.table.Cities)
  const classes = useStyle()

  const columnStatus = React.useMemo(() => {
    if (status === 'onlyTable') {
      return false
    }
    if (status === 'twoInOne') {
      return true
    }
  }, [status])
  const spec: ColumnSpec<Service, TableCellProps, React.ComponentProps<typeof CommonTableHeadCell>>[] = [
    {
      colId: 'title',
      visible: true,
      HeadCellContentRender: () => <span>Название улсуги</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => console.log(item.id)}>{item.service}</ContainerSpan>,
      tableCellProps: { className: classes.textBold, style: { maxWidth: 155 } }
    },
    {
      colId: 'specialization',
      visible: true,
      HeadCellContentRender: () => <span>Специализация</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => console.log(item.id)}>{item.specialization}</ContainerSpan>,
      tableCellProps: { style: { maxWidth: 185 }, className: 'gradientBefore' }
    },
    {
      colId: 'category',
      visible: true,
      HeadCellContentRender: () => <span>Категория</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => console.log(item.id)}>{item.category}</ContainerSpan>,
      tableCellProps: { style: { width: 140 }, className: 'gradientBefore' }
    },
    {
      colId: 'mastersCount',
      visible: true,
      HeadCellContentRender: () => <span>Исполн.</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => console.log(item.id)}>{item.workersCount}</ContainerSpan>,
      tableCellProps: { className: clsx(classes.textBold, classes.textRight, 'gradientBefore'), style: { width: 68 } }
    },
    {
      colId: 'rate',
      visible: true,
      HeadCellContentRender: () => <span>Рейт.</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => console.log(item.id)}> <RateColorStyle rate={item.rating ? item.rating : 0} option={RateOprion} /></ContainerSpan>,
      tableCellProps: { className: clsx(classes.textBold, classes.textRight), style: { width: 44 } }
    },
    {
      colId: 'queries',
      visible: !columnStatus,
      HeadCellContentRender: () => <span>Запросы</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => console.log(item.id)}> <RateColorStyle rate={0} option={RateOprion} /></ContainerSpan>,
      tableCellProps: { className: clsx(classes.textBold, classes.textRight), style: { width: 75 } }
    },
    {
      colId: 'tags',
      visible: !columnStatus,
      HeadCellContentRender: () => <span>Теги</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => console.log(item.id)}><TagsList tags={item.tags} /></ContainerSpan>,
      tableCellProps: { className: classes.overflow }
    },
    {
      colId: 'ids',
      visible: true,
      HeadCellContentRender: () => <span>#id</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => console.log(item.id)}>{`#${item.id}`}</ContainerSpan>,
      tableCellProps: { className: clsx(classes.textLightGray, classes.textRight, !columnStatus && 'gradientBefore'), style: { width: 80 } }
    },
    {
      colId: 'button',
      visible: !columnStatus,
      HeadCellContentRender: () => <span></span>,
      tableHeadCellProps: { style: { width: 32 } },
      CellContentRender: () => <ContainerSpan ><ThreePointSvg /></ContainerSpan>,
      tableCellProps: {
        style: {
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          width: 32
        }
      }
    }
  ]
  return (
    <DataTable<
      Service,
      React.ComponentProps<typeof CommonTable>,
      CommonTableRowProps<Service>,
      CommonTableCellProps<Service>,
      React.ComponentProps<typeof CommonTableHead>,
      React.ComponentProps<typeof CommonTableHeadRow>,
      React.ComponentProps<typeof CommonTableHeadCell>
    >
      data={servicesForCity?.state?.results as Service[]}
      Table={CommonTable}
      TableRow={CommonTableRow}
      TableCell={CommonTableCell}
      TableHead={CommonTableHead}
      TableHeadRow={CommonTableHeadRow}
      TableHeadCell={CommonTableHeadCell}
      spec={spec}
      itemKey={(city: Service) => city.id}
      loading={servicesForCity.loading}
    />
  )
}