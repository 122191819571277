import React from 'react'
import { ColumnSpec } from "../../components/Table/CommonDataTable/DataTable"
import { useStyle } from "../../components/Table/useStyles"
import { TableCellProps } from '@material-ui/core';
import { CommonTableHeadCell } from './../../components/Table/CommonDataTable/CommonTableHeadCell';
import { ContainerSpan } from './../../components/Table/Common/ContainerSpan';
import clsx from 'clsx';
import { ThreePointSvg } from "../../svg";
import { useSelector } from 'react-redux';
import { RootState } from "../../store"
import { Event } from './EventsTable';
import { FullName } from '../../components/FullName';
import { DateView } from './../../components/Table/Common/DateView';

export const useSpec = ({ onClickItem, onClickItemMobile }: { onClickItem?: any, onClickItemMobile?: any }) => {
  const classes = useStyle()
  const status = useSelector((state: RootState) => state.table.Events.status)
  const columnStatus = React.useMemo(() => {
    if (status === 'onlyTable') {
      return false
    }
    if (status === 'twoInOne') {
      return true
    }
  }, [status])
  const spec: ColumnSpec<Event, TableCellProps, React.ComponentProps<typeof CommonTableHeadCell>>[] = [
    {
      colId: "info",
      visible: !columnStatus,
      HeadCellContentRender: () => <span>Роль</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>{item.role}</ContainerSpan>,

    },
    {
      colId: "person",
      visible: true,
      HeadCellContentRender: () => <span>ФИО</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}><FullName data={item.person} /></ContainerSpan>
    },
    {
      colId: "message",
      visible: true,
      HeadCellContentRender: () => <span>Информация</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>{item.message}</ContainerSpan>,
      tableCellProps: { className: classes.textBold }
    },
    {
      colId: "type",
      visible: true,
      HeadCellContentRender: () => <span>Тип</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>{item.type}</ContainerSpan>
    },
    {
      colId: "date",
      visible: !columnStatus,
      HeadCellContentRender: () => <span>Дата</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}><DateView date={item.date} /></ContainerSpan>
    },
    {
      colId: 'ids',
      visible: !columnStatus,
      HeadCellContentRender: () => <span>#id</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>{`#${item.id}`}</ContainerSpan>,
      tableCellProps: { className: clsx(classes.textLightGray, classes.textRight, !columnStatus && 'gradientBefore'), style: { width: 80 } }
    },
    {
      colId: 'button',
      visible: true,
      HeadCellContentRender: () => <span></span>,
      tableHeadCellProps: !columnStatus ? { style: { width: 32 } } : { style: { width: 37 } },
      CellContentRender: () => <ContainerSpan><ThreePointSvg /></ContainerSpan>,
      tableCellProps: {
        style: {
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          width: !columnStatus ? 32 : 27
        }
      }
    }
  ]
  const specMobile: ColumnSpec<Event, TableCellProps, React.ComponentProps<typeof CommonTableHeadCell>>[] = [
    {
      colId: "info",
      visible: true,
      HeadCellContentRender: () => <><span>Информация</span><span className={classes.textLight}>Тип</span></>,
      tableHeadCellProps: {
        style: {
          display: 'flex',
          justifyContent: 'space-between'
        }
      },
      CellContentRender: ({ item }) => <>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={clsx(classes.textColorB, classes.textBold,classes.nameMobile)} >{item.message}</ContainerSpan>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={'gradientBefore'} style={{ position: 'relative' }} >{item.type}</ContainerSpan>
      </>,
      tableCellProps: {
        style: {
          borderRadius: '8px 8px 0px 0px',
          display: 'flex',
          justifyContent: 'space-between'
        }
      }

    },
    {
      colId: "person",
      visible: true,
      HeadCellContentRender: () => <><span style={{ marginRight: 10 }}>Роль</span> <span>ФИО</span></>,
      tableHeadCellProps: {
        style: {
          fontWeight: 400,
          display: 'flex',
        }
      },
      CellContentRender: ({ item }) => <>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={classes.textColorB} style={{ marginRight: 4 }} >{item.role}</ContainerSpan>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={classes.textColorGG}><FullName data={item.person} /></ContainerSpan>
      </>,
      tableCellProps: {
        style: {
          display: 'flex',
        }
      }
    },
    {
      colId: "date",
      visible: true,
      HeadCellContentRender: () => <><span>Дата</span><span className={classes.textLight}>#id</span></>,
      tableHeadCellProps: {
        style: {
          display: 'flex',
          justifyContent: 'space-between'
        }
      },
      CellContentRender: ({ item }) => <>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={clsx(classes.textBold, classes.textColorGG)}><DateView date={item.date} /></ContainerSpan>
        <ContainerSpan
          onClick={() => onClickItemMobile(item.id)}
          className={clsx(classes.textLightGray, 'gradientBefore')}
          style={{ position: 'relative' }}>
          {`#${item.id}`}
        </ContainerSpan>
      </>,
      tableCellProps: {
        style: {
          borderRadius: '0px 0px 8px 8px',
          display: 'flex',
          justifyContent: 'space-between'
        }
      }
    },
  ]
  return { spec, specMobile }
}