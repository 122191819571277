import { makeStyles } from "@material-ui/core"

export const useStyleRaiting = makeStyles({
  notGood: {
    color: '#FB2924'
  },
  normal: {
    color: '#FDAF2D'
  },
  good: {
    color: '#0ED609'
  },
  excellent: {
    background: 'linear-gradient(45deg, #00B3FF 0%, #6A11CB 100%)',
    backgroundClip: 'text',
    "-webkit-background-clip": 'text',
    "-webkit-text-fill-color": 'transparent',
    color: '#00B3FF',
  }
})
interface RateProps {
  option: {
    minimum: number,
    average: number,
    maximum: number,
  }
  rate: number,
  title?: string
}

export const RateColorStyle = ({ rate, option, title }: RateProps) => {
  const classes = useStyleRaiting()
  const { minimum, average, maximum } = option
  if (rate <= minimum) {
    return (
      <span className={classes.notGood}>{title} {rate}</span>
    )
  }
  if (rate > minimum && rate <= average) {
    return (
      <span className={classes.normal}>{title} {rate}</span>
    )
  }
  if (rate > average && rate <= maximum) {
    return (
      <span className={classes.good}>{title} {rate}</span>
    )
  }
  return (
    <span className={classes.excellent}>{title} {rate}</span>
  )
}
