import React from "react"

interface ContainerSpanProps {
  children: React.ReactNode,
  onClick?: any,
  className?: string,
  style?: any
}

export const ContainerSpan = ({ children, onClick, className = 'elemCell', style }: ContainerSpanProps) => {
  return <span
    role='button'
    onClick={onClick}
    className={className}
    style={style}
  >
    {children}
  </span>
}