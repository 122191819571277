import React from 'react'
import { ColumnSpec } from "../../components/Table/CommonDataTable/DataTable"
import { useStyle } from "../../components/Table/useStyles"
import { TableCellProps } from '@material-ui/core';
import { CommonTableHeadCell } from './../../components/Table/CommonDataTable/CommonTableHeadCell';
import { ContainerSpan } from './../../components/Table/Common/ContainerSpan';

import { PointAndText } from "../../components/PointAndText"
import clsx from 'clsx';
import { ThreePointSvg } from "../../svg";
import { useSelector } from 'react-redux';
import { RootState } from "../../store"
import { Notif } from './NotifTable';
import { FullName } from '../../components/FullName';
import { DateView } from './../../components/Table/Common/DateView';

export const useSpec = ({ onClickItem, onClickItemMobile }: { onClickItem?: any, onClickItemMobile?: any }) => {
  const classes = useStyle()
  const status = useSelector((state: RootState) => state.table.Notifications.status)
  const columnStatus = React.useMemo(() => {
    if (status === 'onlyTable') {
      return false
    }
    if (status === 'twoInOne') {
      return true
    }
  }, [status])
  const spec: ColumnSpec<Notif, TableCellProps, React.ComponentProps<typeof CommonTableHeadCell>>[] = [
    {
      colId: "info",
      visible: true,
      HeadCellContentRender: () => <span>Информация</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>{item.title}</ContainerSpan>,
      tableCellProps: { className: clsx(classes.textBold, classes.maxWidthMobile) }
    },
    {
      colId: "type",
      visible: true,
      HeadCellContentRender: () => <span>Тип</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>{item.type}</ContainerSpan>,
      tableCellProps: { style: { width: 71 }, className: 'gradientBefore' }
    },
    {
      colId: "receiver",
      visible: true,
      HeadCellContentRender: () => <span>ФИО</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}><FullName data={item.receiver} /></ContainerSpan>,
      tableCellProps: { className: clsx(classes.maxWidth, 'gradientBefore') }
    },
    {
      colId: "phone",
      visible: true,
      HeadCellContentRender: () => <span>Номер</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>{item.phone}</ContainerSpan>,
      tableCellProps: { className: 'gradientBefore' }
    },
    {
      colId: "role",
      visible: !columnStatus,
      HeadCellContentRender: () => <span>Роль</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>{item.role}</ContainerSpan>
    },
    {
      colId: "status",
      visible: true,
      HeadCellContentRender: () => { return !columnStatus ? <span style={{ paddingLeft: 8 }}>Статус</span> : <span></span> },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}><PointAndText view={!columnStatus} value={item.status} /></ContainerSpan>,
      tableCellProps: { className: clsx(classes.textBold, !columnStatus ? classes.statusBarFull : classes.statusBarSmall) }
    },
    {
      colId: "date",
      visible: !columnStatus,
      HeadCellContentRender: () => <span>Дата</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}><DateView date={item.date} /></ContainerSpan>
    },
    {
      colId: 'ids',
      visible: !columnStatus,
      HeadCellContentRender: () => <span>#id</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => onClickItem(item.id)}>{`#${item.id}`}</ContainerSpan>,
      tableCellProps: { className: clsx(classes.textLightGray, classes.textRight, !columnStatus && 'gradientBefore'), style: { width: 80 } }
    },
    {
      colId: 'button',
      visible: true,
      HeadCellContentRender: () => <span></span>,
      tableHeadCellProps: !columnStatus ? { style: { width: 32 } } : { style: { width: 37 } },
      CellContentRender: () => <ContainerSpan><ThreePointSvg /></ContainerSpan>,
      tableCellProps: {
        style: {
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          width: !columnStatus ? 32 : 27
        }
      }
    }
  ]
  const specMobile: ColumnSpec<Notif, TableCellProps, React.ComponentProps<typeof CommonTableHeadCell>>[] = [
    {
      colId: "info",
      visible: true,
      HeadCellContentRender: () => <><span>Информация</span><span style={{ fontWeight: 300, marginRight: 8 }}>Тип</span></>,
      tableHeadCellProps: {
        style: {
          display: 'flex',
          justifyContent: 'space-between'
        }
      },
      CellContentRender: ({ item }) => <>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)}
          className={clsx(classes.textColorB, classes.textBold)}>
          {item.title}
        </ContainerSpan>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)}>{item.type}</ContainerSpan>
      </>,
      tableCellProps: {
        style: {
          borderRadius: '8px 8px 0px 0px',
          display: 'flex',
          justifyContent: 'space-between'
        }
      }
    },
    {
      colId: "receiver",
      visible: true,
      HeadCellContentRender: () => <><span style={{ marginRight: 10 }}>Роль</span><span>ФИО</span></>,
      tableHeadCellProps: {
        style: {
          fontWeight: 400,
          display: 'flex'
        }
      },
      CellContentRender: ({ item }) => <>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={classes.textColorB} style={{ marginRight: 4 }} >{item.role}</ContainerSpan>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)}><FullName data={item.receiver} /></ContainerSpan>
      </>,
      tableCellProps: { style: { display: 'flex' } }
    },
    {
      colId: "status",
      visible: true,
      HeadCellContentRender: () => <>
        <span style={{ marginLeft: '-8px' }}><PointAndText view={true} value={'default'} /></span>
        <span style={{ marginLeft: 8, fontWeight: 400 }}>Дата</span>
        <span className={clsx(classes.textLight)} style={{ marginLeft: 'auto' }}>#id</span>
      </>,
      tableHeadCellProps: {
        style: {
          display: 'flex'
        }
      },
      CellContentRender: ({ item }) => <>
        <span style={{ maxWidth: '100px', display: 'flex' }}>
          <ContainerSpan onClick={() => onClickItemMobile(item.id)}
            className={clsx(classes.textBold, classes.textColorB)}
            style={{ marginLeft: '-8px' }}>
            <PointAndText view={true} value={item.status} page={'Notif'} />
          </ContainerSpan>
          <ContainerSpan onClick={() => onClickItemMobile(item.id)} style={{ marginLeft: 12 }}><DateView date={item.date} /></ContainerSpan>
        </span>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={clsx('elemCell gradientBefore', classes.textLightGray)} style={{ marginLeft: 'auto', position: 'relative' }}>{`#${item.id}`}</ContainerSpan>
      </>,
      tableCellProps: {
        style: {
          borderRadius: '0px 0px 8px 8px',
          display: 'flex'
        }
      }
    },
  ]
  return { spec, specMobile }
}