import React from 'react'
import { ColumnSpec } from "../../components/Table/CommonDataTable/DataTable"
import { useStyle } from "../../components/Table/useStyles"
import { TableCellProps } from '@material-ui/core';
import { CommonTableHeadCell } from './../../components/Table/CommonDataTable/CommonTableHeadCell';
// import { Cities } from './CitiesTable'
import { City } from '../../entities/City'
import { ContainerSpan } from './../../components/Table/Common/ContainerSpan';
import { RateColorStyle } from './../../components/Table/Common/RateColorStyle';
import clsx from 'clsx';
import { TagsList } from './../../components/Table/Common/TagList';
import { ThreePointSvg } from "../../svg";
import { useSelector } from 'react-redux';
import { RootState } from "../../store";
import { NumberDigits } from '../../utils/NumberRender'

export const RatingCityOprion = {
  minimum: 50,
  average: 70,
  maximum: 90,
}

export const useSpec = ({ onClickItem, onClickItemMobile }: { onClickItem?: any, onClickItemMobile?: any }) => {
  const classes = useStyle()
  const status = useSelector((state: RootState) => state.city.status)
  const columnStatus = React.useMemo(() => {
    if (status === 'onlyTable') {
      return false
    }
    if (status === 'twoInOne') {
      return true
    }
  }, [status])
  const spec: ColumnSpec<City, TableCellProps, React.ComponentProps<typeof CommonTableHeadCell>>[] = [
    {
      colId: 'title',
      visible: true,
      HeadCellContentRender: () => <span>Населённый пункт</span>,
      CellContentRender: ({ item, index }) => <ContainerSpan onClick={() => onClickItem(item, index)}>{item.title}</ContainerSpan>,
      tableCellProps: { className: classes.textBold, style: { width: 300 } }
    },
    {
      colId: 'area',
      visible: true,
      HeadCellContentRender: () => <span>Область</span>,
      CellContentRender: ({ item, index }) => <ContainerSpan onClick={() => onClickItem(item, index)}>{item.region} </ContainerSpan>,
      tableCellProps: { style: { width: 138 } }

    },
    {
      colId: 'rate',
      visible: true,
      HeadCellContentRender: () => <span>Рейт.</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item, index }) => <ContainerSpan onClick={() => onClickItem(item, index)}><RateColorStyle rate={item.rating} option={RatingCityOprion} /></ContainerSpan>,
      tableCellProps: { className: clsx(classes.textBold, classes.textRight), style: { width: 44 } }
    },
    {
      colId: 'employeerCount',
      visible: true,
      HeadCellContentRender: () => <span>Исполн.</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item, index }) => <ContainerSpan onClick={() => onClickItem(item, index)}>{NumberDigits(item.workersCount)}</ContainerSpan>,
      tableCellProps: { className: clsx(classes.textBold, classes.textRight), style: { width: 62 } }
    },
    {
      colId: 'population',
      visible: !columnStatus,
      HeadCellContentRender: () => <span>Насел.</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item, index }) => <ContainerSpan onClick={() => onClickItem(item, index)}>{NumberDigits(item.population)}</ContainerSpan>,
      tableCellProps: { className: clsx(classes.textBold, classes.textRight), style: { width: 62 } }
    },
    {
      colId: 'tags',
      visible: !columnStatus,
      HeadCellContentRender: () => <span>Теги</span>,
      CellContentRender: ({ item, index }) => <ContainerSpan onClick={() => onClickItem(item, index)}><TagsList /></ContainerSpan>,
      tableCellProps: { className: classes.overflow }
    },
    {
      colId: 'ids',
      visible: true,
      HeadCellContentRender: () => <span>#id</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item, index }) => <ContainerSpan onClick={() => onClickItem(item, index)}>{`#${item.id}`}</ContainerSpan>,
      tableCellProps: { className: clsx(classes.textLightGray, classes.textRight, !columnStatus && 'gradientBefore'), style: { width: 80 }, }
    },
    {
      colId: 'button',
      visible: !columnStatus,
      HeadCellContentRender: () => <span></span>,
      tableHeadCellProps: { style: { width: 32 } },
      CellContentRender: () => <ContainerSpan ><ThreePointSvg /></ContainerSpan>,
      tableCellProps: {
        style: {
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          width: 32
        }
      }
    }
  ]
  const specMobile: ColumnSpec<City, TableCellProps, React.ComponentProps<typeof CommonTableHeadCell>>[] = [
    {
      colId: 'title',
      visible: true,
      HeadCellContentRender: () => <>
        <span>Населённый пункт</span>
        <span className={classes.textLightGray} style={{ marginRight: 8 }}>Область</span>
      </>,
      tableHeadCellProps: {
        style: {
          display: 'flex',
          justifyContent: 'space-between'
        }
      },
      CellContentRender: ({ item }) => <>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} style={{ display: 'flex', width: '100%' }}>{item.title}</ContainerSpan>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={classes.textLight}>{item.region} </ContainerSpan>
      </>,
      tableCellProps: {
        className: classes.textBold, style: {
          borderRadius: '8px 8px 0px 0px',
          display: 'flex',
          justifyContent: 'space-between'
        }
      }
    },
    {
      colId: 'info',
      visible: true,
      HeadCellContentRender: () => <>
        <span>Рейтинг</span>
        <span style={{ marginLeft: 8, marginRight: 6 }}>Население</span>
        <span className={classes.textLightGray}>Исполнителей</span>
        <span className={classes.textLightGray} style={{ marginLeft: 'auto' }}>#id</span>
      </>,
      tableHeadCellProps: {
        style: {
          display: 'flex',
        }
      },
      CellContentRender: ({ item }) => <>
        <span style={{ maxWidth: '100px', display: 'flex' }}>
          <ContainerSpan onClick={() => onClickItemMobile(item.id)}><RateColorStyle rate={item.rating} option={RatingCityOprion} title={'Рейт.'} /></ContainerSpan>
          <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={classes.textColorGG} style={{ margin: '0px 12px' }}> <span>Нас. </span>{NumberDigits(0)}</ContainerSpan>
          <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={classes.textLightGray}><span>Исполн. </span>{NumberDigits(item.workersCount)}</ContainerSpan>
        </span>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={clsx('elemCell gradientBefore', classes.textLightGray)} style={{ marginLeft: 'auto', position: 'relative' }}>{`#${item.id}`}</ContainerSpan>
      </>,
      tableCellProps: {
        className: classes.textBold, style: {
          borderRadius: '0px 0px 8px 8px',
          display: 'flex'
        }
      }
    }
  ]
  return { spec, specMobile }
}