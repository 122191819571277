import React from "react"
import clsx from "clsx"
import {
  TableCellProps,
} from "@material-ui/core"
import { CommonTable } from "../../components/Table/CommonDataTable/CommonTable"
import { ColumnSpec, DataTable } from "../../components/Table/CommonDataTable/DataTable"
import { CommonTableRow, CommonTableRowProps } from "../../components/Table/CommonDataTable/CommonTableRow"
import { CommonTableCell, CommonTableCellProps } from "../../components/Table/CommonDataTable/CommonTableCell"
import { CommonTableHeadRow } from "../../components/Table/CommonDataTable/CommonTableHeadRow"
import { CommonTableHeadCell } from "../../components/Table/CommonDataTable/CommonTableHeadCell"
import { CommonTableHead } from "../../components/Table/CommonDataTable/CommonTableHead"

import { ThreePointSvg } from "../../svg"
import { TagsList } from "../../components/Table/Common/TagList"
import { RateColorStyle } from "../../components/Table/Common/RateColorStyle"
import { useStyle } from "../../components/Table/useStyles"
import { FullName } from "../../components/FullName"
import { ContainerSpan } from "../../components/Table/Common/ContainerSpan"
import { Performer } from '../../entities/Performer'
import { NumberDigits } from "../../utils/NumberRender"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import { useHistory } from "react-router-dom"

const RateOprion = {
  minimum: 50,
  average: 70,
  maximum: 90,
}

export const TabsPerformer = () => {
  const { status, performersForCity } = useSelector((state: RootState) => state.city)
  const classes = useStyle()

  const columnStatus = React.useMemo(() => {
    if (status === 'onlyTable') {
      return false
    }
    if (status === 'twoInOne') {
      return true
    }
  }, [status])
  const history = useHistory()

  const PushToPerformer = (id: number) => {
    history.push(`/performers/${id}`)
  }
  const spec: ColumnSpec<Performer, TableCellProps, React.ComponentProps<typeof CommonTableHeadCell>>[] = [
    {
      colId: 'name',
      visible: true,
      HeadCellContentRender: () => <span>Имя</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => PushToPerformer(item.id)}><FullName data={{ firstname: item.firstName, lastname: item.lastName, patronymic: item.patronymic }} /></ContainerSpan>,
      tableCellProps: { className: classes.textBold, style: { width: 415 } }
    },
    {
      colId: 'rate',
      visible: true,
      HeadCellContentRender: () => <span>Рейт.</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => PushToPerformer(item.id)}><RateColorStyle option={RateOprion} rate={item.rating ? item.rating : 0} /></ContainerSpan>,
      tableCellProps: { className: clsx(classes.textBold, classes.textRight), style: { width: 44 } }
    },
    {
      colId: 'orders',
      visible: true,
      HeadCellContentRender: () => <span>Заказов</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => PushToPerformer(item.id)}>{NumberDigits(item.ordersCount)}</ContainerSpan>,
      tableCellProps: { className: clsx(classes.textBold, classes.textRight), style: { width: 68 } }
    },
    {
      colId: 'tags',
      visible: !columnStatus,
      HeadCellContentRender: () => <span>Теги</span>,
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => PushToPerformer(item.id)}><TagsList tags={item.tags} /></ContainerSpan>,
      tableCellProps: { className: classes.overflow }
    },
    {
      colId: 'id',
      visible: true,
      HeadCellContentRender: () => <span>#id</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item }) => <ContainerSpan onClick={() => PushToPerformer(item.id)}>{`#${item.id}`}</ContainerSpan>,
      tableCellProps: { className: clsx(classes.textLightGray, classes.textRight, !columnStatus && 'gradientBefore'), style: { width: 80 } }

    },
    {
      colId: 'button',
      visible: !columnStatus,
      HeadCellContentRender: () => <span></span>,
      tableHeadCellProps: { style: { width: 32 } },
      CellContentRender: () => <ContainerSpan ><ThreePointSvg /></ContainerSpan>,
      tableCellProps: {
        style: {
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          width: 32
        }
      }
    }
  ]

  return (
    <DataTable<
      Performer,
      React.ComponentProps<typeof CommonTable>,
      CommonTableRowProps<Performer>,
      CommonTableCellProps<Performer>,
      React.ComponentProps<typeof CommonTableHead>,
      React.ComponentProps<typeof CommonTableHeadRow>,
      React.ComponentProps<typeof CommonTableHeadCell>
    >
      data={performersForCity?.state?.results as Performer[]}
      Table={CommonTable}
      TableRow={CommonTableRow}
      TableCell={CommonTableCell}
      TableHead={CommonTableHead}
      TableHeadRow={CommonTableHeadRow}
      TableHeadCell={CommonTableHeadCell}
      spec={spec}
      itemKey={(performer: Performer) => performer.id}
      loading={performersForCity.loading}
    />
  )
}
