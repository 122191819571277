import React from 'react'
import { Box, withStyles, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const TableRowInfoBox = withStyles({
  root: {
    borderRadius: '8px',
    padding: '12px 4px 20px 4px',
    background: '#FFFFFF',
    height: '95vh',
    // position: 'sticky',
    // top: '0px',
    // right: '0px'
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: 0
    }
  }
})(Box)

const useStatus = makeStyles({
  onlyTable: {
    display: 'none'
  },
  twoInOne: {
    width: '50%',
    transition: 'all .3s',
    marginLeft: '15px'
  },
  onlyInfo: {
    width: '100%',
    transition: 'all .3s'
  },
  scrollInfo: {
    position: 'fixed',
    top: 60,
    right: -8,
    // translate: 'all .3s',
    width:'calc(50% - 15px)'
  }
})

export const InfoCols = ({ children, status }: { children: React.ReactNode, status: string }) => {
  const [scrollCheck, setScrollCheck] = React.useState(false)
  const classes = useStatus()


  const className = React.useMemo(() => {
    if (status === 'onlyTable')
      return classes.onlyTable
    if (status === 'twoInOne')
      return classes.twoInOne
    if (status === 'onlyInfo')
      return classes.onlyInfo
  }, [status, classes])

  React.useEffect(() => {
    const ScrollForPage = () => {
      if (window.pageYOffset >= 77) {
        setScrollCheck(true)
      } else {
        setScrollCheck(false)
      }
    }
    document.addEventListener('scroll', ScrollForPage)
    return () => document.removeEventListener('scroll', ScrollForPage)
  }, [setScrollCheck])

  return (
    <TableRowInfoBox className={clsx(className, scrollCheck && classes.scrollInfo)}>
      {children}
    </TableRowInfoBox>
  )
}