import { makeStyles } from "@material-ui/core"

const useStyle = makeStyles({
  notGood: {
    color: '#FB2924'
  },
  normal: {
    color: '#FDAF2D'
  },
  good: {
    color: '#0ED609'
  },
  excellent: {
    background: 'linear-gradient(45deg, #00B3FF 0%, #6A11CB 100%)',
    backgroundClip: 'text',
    "-webkit-background-clip": 'text',
    "-webkit-text-fill-color": 'transparent',
    color: '#00B3FF',
  }
})


export const RaitingStyle = (value: number, option: { minimum: number, average: number, maximum: number }) => {
  const { minimum, average } = option
  const { notGood, normal, good } = useStyle()
  if (value <= minimum) {
    return notGood
  }
  if (value > minimum && value <= average) {
    return normal
  }
  return good
}