import React from "react";
import { Header } from "../../components/TableRowInfo/Header";
import { TagsList } from "../../components/TableRowInfo/Tags";
import { Parameter, ParameterItem } from "../../components/TableRowInfo/Parameter";
import { NumberDigits } from "../../utils/NumberRender";
import { TableRowInfo } from "../../components/TableRowInfo";
import TabInfo from "../../components/TableRowInfo/TabInfo";
import { TabsSettings } from "./TabSettings";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useHistory } from "react-router-dom";
import { backPerformer, closeInfoPerformer, nextPerformer, openInfoPage, openInfoPerformer } from "../../store/Performers/performerStore";
import NumberFormat from "react-number-format";
import { Link } from "@material-ui/core";

const tags = [
    {
        "title": "tagname",
        "count": 10
    },
    {
        "title": "tagname",
        "count": 10
    },
    {
        "title": "tagname",
        "count": 10
    },
    {
        "title": "tagname",
        "count": 10
    },
    {
        "title": "tagname",
        "count": 10
    },
    {
        "title": "tagname",
        "count": 10
    },
    {
        "title": "tagname",
        "count": 10
    },
    {
        "title": "tagname",
        "count": 10
    }
];

const tabInfo = [
    {
        name: 'Услуги',
        component: <h1>В разработке</h1>
    },
    {
        name: 'Записи',
        component: <h1>В разработке</h1>
    },
    {
        name: 'Действия',
        component: <h1>В разработке</h1>
    },
    {
        name: 'Расписание',
        component: <h1>В разработке</h1>
    },
    {
        name: 'Клиенты',
        component: <h1>В разработке</h1>
    },
    {
        name: 'Настройки',
        component: <TabsSettings />
    }
]

export const PerformersInfoBlock = ({ statusInfo }: { statusInfo: string }) => {
    const dispatch = useAppDispatch()
    const { activeItem, activeUser, activeItemIndex } = useSelector((state: RootState) => state.performer)
    const history = useHistory()

    const openFullInfo = () => {
        if (activeItem) {
            dispatch(openInfoPage())
            history.push(`/performers/${activeItem.id}`);
        }
    }
    const closeInfo = () => {
        dispatch(closeInfoPerformer())
    }
    const nextInfo = () => {
        dispatch(nextPerformer())
    }
    const backInfo = () => {
        dispatch(backPerformer())
    }

    const Parameters = () => {
        return (
            <Parameter >
                <ParameterItem params={[{ name: 'Имя', value: activeUser?.firstName }]} />
                <ParameterItem params={[{ name: 'Фамилия', value: activeUser?.lastName }, { name: 'id', value: activeUser?.id }]} />
                <ParameterItem params={[
                    { name: 'Отчество', value: activeUser?.patronymic },
                    {
                        name: 'url',
                        value: <Link href={activeUser?.webFormUrl} target="_blank" rel="noopener">{activeUser?.webFormUrl?.replace('https://', '')}</Link>
                    }
                ]} />
                <ParameterItem params={[{ name: 'Телефон', value: <NumberFormat value={activeUser?.phone} displayType='text' mask={'_'} prefix={'+375'} format='+### ## ### ## ##' /> }, { name: 'Дата регистр.', value: '' }]} />
                <ParameterItem params={[{ name: 'Город', value: activeUser?.city.title }]} />
            </Parameter>
        )
    }
    const Name = () => {
        return `${activeItem?.firstName} ${activeItem?.lastName} ${activeItem?.patronymic}`
    }
    const PageParameters = () => {
        return (
            <Parameter >
                <ParameterItem params={[{ name: 'Имя', value: activeUser?.firstName }, { name: 'Город', value: activeUser?.city.title }, { name: 'Дата регистр.', value: '' }]} />
                <ParameterItem params={[{ name: 'Фамилия', value: activeUser?.lastName }, { name: 'id', value: activeUser?.id }, { name: 'Рейтинг', value: activeItem?.rating }]} />
                <ParameterItem params={[{ name: 'Отчество', value: activeUser?.patronymic }, { name: 'url', value: <Link href={activeUser?.webFormUrl} target="_blank" rel="noopener">{activeUser?.webFormUrl?.replace('https://', '')}</Link> }, { name: '' }]} />
                <ParameterItem params={[{ name: 'Телефон', value: <NumberFormat value={activeUser?.phone} displayType='text' mask={'_'} prefix={'+375'} format='+### ## ### ## ##' /> }, { name: '' }, { name: '' }]} />
            </Parameter>
        )
    }
    return (
        <TableRowInfo >
            <Header
                title={Name()}
                accordion={true}
                expanded={true}
                onOpen={openFullInfo}
                onClose={closeInfo}
                onNext={nextInfo}
                onBack={backInfo}
                status={statusInfo}
                indexItem={activeItemIndex}
            >
                <TagsList tags={tags} />
                {
                    statusInfo === 'onlyInfo' ?
                        <PageParameters />
                        :
                        <Parameters />
                }
            </Header>
            <TabInfo data={tabInfo} />
        </TableRowInfo >
    )
}