import * as Yup from "yup";
import {TFunction} from "react-i18next";

export const yupLocaleInit = (t: TFunction) => {
  Yup.setLocale({
    mixed: {
      required: t('required')
    },
  });
}

export const commaSeparatedNumberSchema = (t: TFunction) => Yup.number()
  .transform((_value, originalValue) => Number(`${originalValue}`.replace(/,/, '.')))
  .typeError(t('number-required'))
  .min(0, t('number-min-0-required'))
  .required();