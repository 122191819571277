import React from "react"
import { Box, Checkbox, Grid, IconButton, InputAdornment, makeStyles, SvgIcon, TextField, Typography, withStyles } from "@material-ui/core"
import { ImageFile, thumbnailFirst } from "../../../entities/ImageFile"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import { ReactComponent as IconBasket } from '../../../icons/icon-basket.svg'
import { ReactComponent as IconPencil } from '../../../icons/icon-pencil.svg'
import { ReactComponent as IconCopy } from '../../../icons/icon-copy.svg'
import { Profile } from "../../entities/Performer"
import { GradientImage } from "../../../common/GradientImage/GradientImage"
const useStyles = makeStyles({
    label: {
        fontSize: "16px",
        lineHeight: "22px",
        color: "#000000"
    },
    gridRow: {
        marginBottom: "16px",
        position: 'relative'
    },
    textarea: {
        "& .MuiInputBase-inputMultiline": {
            height: "130px!important"
        }
    },
    copyBtn: {
        position: 'absolute',
        right: 0,
        top: 0,
        borderRadius: 8,
        boxSizing: 'border-box'
    },
    coverImageBox: {
        background: '#EBF3F7',
        borderRadius: 8,
        padding: 16,
        boxSizing: 'border-box',
    },
    coverImage: {
        height: 98,
        width: '100%',
        objectFit: 'cover'
    },
    avatarImageBox: {
        borderRadius: '50%',
    },
    avatarImage: {
        height: 130,
        width: 130,
        objectFit: 'cover',
        borderRadius: '50%'
    },
    notImage: {
        width: '100%',
        height: '100%',
        borderRadius: 'inherit',
        background: 'rgba(0, 0, 0, 0) linear-gradient(45deg, rgb(0, 179, 255) 0%, rgb(106, 17, 203) 100%) repeat scroll 0% 0%',
        display: 'block'
    },
    pointColor: {
        '& input': {
            color: '#2475FB'
        }
    },
    linkPlaceholder:{

    }
})

const GridCus = withStyles({
    root: {
        padding: "5px 50px"
    }
})(Grid)

export const TabsSettings = () => {
    const style = useStyles()
    const { activeUser, status } = useSelector((state: RootState) => state.performer)

    return (
        <Grid container>
            <ColumnOneSettings status={status} activeUser={activeUser} />
            <ColumnTwoSettings status={status} activeUser={activeUser} />
        </Grid>
    )
}
const ColumnTwoSettings = ({ activeUser, status }: { activeUser?: Profile, status?: string }) => {
    const style = useStyles();
    const avatar = thumbnailFirst(activeUser?.avatar)
    const cover = thumbnailFirst(activeUser?.cover)
    return (
        <GridCus container sm={status === 'onlyInfo' ? 6 : 12}>
            <Grid className={style.gridRow} container>
                <Grid item container alignItems={'center'} sm={2}>
                    <Typography className={style.label}>Дом</Typography>
                </Grid>
                <Grid item sm={9}>
                    <TextField
                        fullWidth={true}
                        type="text"
                        variant="standard"
                    />
                </Grid>
                <Grid item sm={1}>
                    <IconButton
                        style={{ width: 24, height: 24, borderRadius: 8, padding: 10, boxSizing: 'content-box' }}>
                        <SvgIcon component={IconBasket} viewBox='0 0 24 24' style={{ height: 24, width: 24 }} />
                    </IconButton>
                    <IconButton style={{ width: 24, height: 24, borderRadius: 8, padding: 10, boxSizing: 'content-box' }}>
                        <SvgIcon component={IconPencil} viewBox='0 0 24 24' style={{ height: 24, width: 24 }} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid className={style.gridRow} container>
                <Grid item container alignItems={'center'} sm={2}>
                    <Typography className={style.label}>Офис</Typography>
                </Grid>
                <Grid item sm={9}>
                    <TextField
                        fullWidth={true}
                        type="text"
                        variant="standard"
                    />
                </Grid>
                <Grid item sm={1}>
                    <IconButton
                        style={{ width: 24, height: 24, borderRadius: 8, padding: 10, boxSizing: 'content-box' }}>
                        <SvgIcon component={IconBasket} viewBox='0 0 24 24' style={{ height: 24, width: 24 }} />
                    </IconButton>
                    <IconButton style={{ width: 24, height: 24, borderRadius: 8, padding: 10, boxSizing: 'content-box' }}>
                        <SvgIcon component={IconPencil} viewBox='0 0 24 24' style={{ height: 24, width: 24 }} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid className={style.gridRow} container>
                <Grid item container alignItems={'center'} sm={2}>
                    <Typography className={style.label}>На выезде</Typography>
                </Grid>
                <Grid item sm={9}>
                    <TextField
                        className={style.pointColor}
                        fullWidth={true}
                        type="text"
                        variant="standard"
                        value={`${activeUser?.city.location?.coordinates[0]}, ${activeUser?.city.location?.coordinates[1]}`}
                    />
                </Grid>
                <Grid item sm={1}>
                    <IconButton
                        style={{ width: 24, height: 24, borderRadius: 8, padding: 10, boxSizing: 'content-box' }}>
                        <SvgIcon component={IconBasket} viewBox='0 0 24 24' style={{ height: 24, width: 24 }} />
                    </IconButton>
                    <IconButton style={{ width: 24, height: 24, borderRadius: 8, padding: 10, boxSizing: 'content-box' }}>
                        <SvgIcon component={IconPencil} viewBox='0 0 24 24' style={{ height: 24, width: 24 }} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid className={style.gridRow} container>
                <Grid item container alignItems={'flex-start'} sm={2}>
                    <Typography className={style.label}>Обложка</Typography>
                </Grid>
                <Grid item sm={9}>
                    <Box className={style.coverImageBox} height={130}>
                        <GradientImage className={style.coverImage} src={cover} />
                    </Box>
                </Grid>
                <Grid item container alignItems={'center'} sm={1}>
                    <IconButton
                        style={{ width: 24, height: 24, borderRadius: 8, padding: 10, boxSizing: 'content-box' }}>
                        <SvgIcon component={IconBasket} viewBox='0 0 24 24' style={{ height: 24, width: 24 }} />
                    </IconButton>
                    <IconButton style={{ width: 24, height: 24, borderRadius: 8, padding: 10, boxSizing: 'content-box' }}>
                        <SvgIcon component={IconPencil} viewBox='0 0 24 24' style={{ height: 24, width: 24 }} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid className={style.gridRow} container>
                <Grid item container alignItems={'flex-start'} sm={2}>
                    <Typography className={style.label}>Фото профиля</Typography>
                </Grid>
                <Grid item sm={2}>
                    {/* <Box className={style.avatarImageBox} height={130} width={130}> */}
                    <GradientImage className={style.avatarImage} src={avatar} />
                    {/* </Box> */}
                </Grid>
                <Grid item sm={1} container alignItems={'center'}>
                    <IconButton
                        style={{ width: 24, height: 24, borderRadius: 8, padding: 10, boxSizing: 'content-box' }}>
                        <SvgIcon component={IconBasket} viewBox='0 0 24 24' style={{ height: 24, width: 24 }} />
                    </IconButton>
                    <IconButton style={{ width: 24, height: 24, borderRadius: 8, padding: 10, boxSizing: 'content-box' }}>
                        <SvgIcon component={IconPencil} viewBox='0 0 24 24' style={{ height: 24, width: 24 }} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid className={style.gridRow} container>
                <Grid item container alignItems={'flex-start'} sm={2}>
                    <Typography className={style.label}>Уведомления</Typography>
                </Grid>
                <Grid item sm={10}>
                    <Box>
                        <label><Checkbox color="primary" />
                            Уведомлять за 2 часа до записи
                        </label>
                    </Box>
                    <Box>
                        <label><Checkbox color="primary" />
                            Уведомлять за 24 часа до записи
                        </label>
                    </Box>
                    <Box>
                        <label><Checkbox color="primary" />
                            Уведомлять об отмене записи
                        </label>
                    </Box>
                </Grid>
            </Grid>
        </GridCus>
    )
}
const ColumnOneSettings = ({ status, activeUser }: { status?: string, activeUser?: Profile }) => {
    const style = useStyles()

    const onCopy = () => {
        var textField = document.createElement('textarea')
        textField.innerText = `${activeUser?.webFormUrl}`
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    return (
        <GridCus container sm={status === 'onlyInfo' ? 6 : 12}>
            <Grid className={style.gridRow} container>
                <Grid item container alignItems={'center'} sm={2}>
                    <Typography className={style.label}>Имя</Typography>
                </Grid>
                <Grid item sm={10}>
                    <TextField
                        fullWidth={true}
                        type="text"
                        variant="standard"
                        value={activeUser?.firstName}
                    />
                </Grid>
            </Grid>
            <Grid className={style.gridRow} container>
                <Grid item container alignItems={'center'} sm={2}>
                    <Typography className={style.label}>Фамилия</Typography>
                </Grid>
                <Grid item sm={10}>
                    <TextField
                        fullWidth={true}
                        type="text"
                        variant="standard"
                        value={activeUser?.lastName}
                    />
                </Grid>
            </Grid>
            <Grid className={style.gridRow} container>
                <Grid item container alignItems={'center'} sm={2}>
                    <Typography className={style.label}>Отчество</Typography>
                </Grid>
                <Grid item sm={10}>
                    <TextField
                        fullWidth={true}
                        type="text"
                        variant="standard"
                        value={activeUser?.patronymic}
                    />
                </Grid>
            </Grid>
            <Grid className={style.gridRow} container>
                <Grid item container alignItems={'center'} sm={2}>
                    <Typography className={style.label}>Телефон</Typography>
                </Grid>
                <Grid item sm={10}>
                    <TextField
                        fullWidth={true}
                        type="text"
                        variant="standard"
                        value={activeUser?.phone}
                    />
                </Grid>
            </Grid>
            <Grid className={style.gridRow} container>
                <Grid item container alignItems={'flex-start'} sm={2}>
                    <Typography className={style.label}>Описание</Typography>
                </Grid>
                <Grid item sm={10}>
                    <TextField
                        fullWidth={true}
                        multiline={true}
                        type="text"
                        variant="standard"
                        className={style.textarea}
                    />
                </Grid>
            </Grid>
            <Grid className={style.gridRow} container>
                <Grid item container alignItems={'center'} sm={2}>
                    <Typography className={style.label}>Населенный пункт</Typography>
                </Grid>
                <Grid item sm={10}>
                    <TextField
                        fullWidth={true}
                        type="text"
                        variant="standard"
                        value={activeUser?.city.title}
                    />
                </Grid>
            </Grid>
            <Grid className={style.gridRow} container>
                <Grid item container alignItems={'center'} sm={2}>
                    <Typography className={style.label}>Личный URL</Typography>
                </Grid>
                <Grid item sm={10}>
                    <TextField
                        fullWidth={true}
                        type="text"
                        variant="standard"
                        value={activeUser?.webFormUrl?.replace('https://', '')}
                        // InputProps={{
                        //     // startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                        //     startAdornment: <span>test.b2c.by/</span>
                        // }}
                    />
                </Grid>
                <IconButton className={style.copyBtn} onClick={onCopy}>
                    <SvgIcon component={IconCopy} viewBox='0 0 16 16' />
                </IconButton>
            </Grid>
            <Grid className={style.gridRow} container>
                <Grid item sm={12}>
                    <label><Checkbox
                        color="primary"
                    />
                        Видимость в поиске
                    </label>
                </Grid>
            </Grid>
            <Grid className={style.gridRow} container>
                <Grid item sm={12}>
                    <label><Checkbox
                        color="primary"
                    />
                        Возможность записаться
                    </label>
                </Grid>
            </Grid>
        </GridCus>
    )
}