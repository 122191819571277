import React from 'react';
import ReactDOM from 'react-dom';
import '../index.css';
import {DashboardApp} from './DashboardApp';

ReactDOM.render(
  <React.StrictMode>
    <DashboardApp/>
  </React.StrictMode>,
  document.getElementById('root')
);
