import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { Performers, Performer, Profile } from '../../entities/Performer'
import { getPerformerById, getProfilesById, loadPerformers, nextPagePerformer } from './thunks'

interface PerformerStore {
  status: string,
  activeItem?: Performer | null,
  activeItemId?: number,
  activeItemIndex?: number,
  list: Performers[] | any,
  listLoading: true | false
  pagination?: {
    id: number,
    title: string
  }[],
  activeUser?: Profile,
  activeUserLoading?: boolean
}

const initialState: PerformerStore = {
  status: 'onlyTable',
  list: [],
  activeItem: null,
  activeItemId: 0,
  activeItemIndex: 0,
  listLoading: true,
  activeUserLoading: false
}

export const performerStore = createSlice({
  name: "performerStore",
  initialState,
  reducers: {
    openInfoPerformer: (state, action: PayloadAction<{ item: Performer, index: number }>) => {
      state.activeItem = action.payload.item
      state.activeItemId = action.payload.item.id
      state.activeItemIndex = action.payload.index
      state.status = 'twoInOne'
    },
    closeInfoPerformer: state => {
      state.status = 'onlyTable'
      state.activeItem = null
      state.activeItemId = 0
      state.activeItemIndex = 0
    },
    nextPerformer: state => {
      if (state.activeItemIndex !== undefined) {
        if (state.list?.length !== 0) {
          state.activeItem = state.list[0].results[state.activeItemIndex + 1]
          state.activeItemId = state.list[0].results[state.activeItemIndex + 1].id
          state.activeItemIndex = state.activeItemIndex + 1
        }
      }
    },
    backPerformer: state => {
      if (state.activeItemIndex !== undefined && (state.activeItemIndex - 1) >= 0) {
        if (state.list?.length !== 0) {
          state.activeItem = state.list[0].results[state.activeItemIndex - 1]
          state.activeItemId = state.list[0].results[state.activeItemIndex - 1].id
          state.activeItemIndex = state.activeItemIndex - 1
        }
      }
    },
    openInfoPage: state => {
      state.status = 'onlyInfo'
    },
    statusLoadPage: (state, action: PayloadAction<{ status: string }>) => {
      state.status = action.payload.status
    }
  },
  extraReducers: (builder) => {
    //Первичная загрузка данных
    builder.addCase(loadPerformers.pending, (state) => {
      state.listLoading = true
    })
    builder.addCase(loadPerformers.fulfilled, (state, action) => {
      state.list?.push(action.payload)
      state.listLoading = false

      const pagin = []
      const count = Math.trunc((action.payload?.count ? action.payload?.count : 0) / 100) + (2025 % 100 > 0 ? 1 : 0)
      for (let index = 0; index < count; index++) {
        pagin.push({ id: index, title: `Стр. ${index + 1}` })
      }
      state.pagination = pagin
    })
    // Загрузка следующей страницы по ссылке
    builder.addCase(nextPagePerformer.pending, (state) => {
      state.listLoading = true
    })
    builder.addCase(nextPagePerformer.fulfilled, (state, action) => {
      if (state.list[state.list.length - 1].next !== action.payload?.next) {
        state.list?.push(action.payload)
      }
      state.listLoading = false
    })
    //загрузка для отдельного профиля
    builder.addCase(getPerformerById.fulfilled, (state, action) => {
      state.activeItem = action.payload
      state.activeItemId = action.payload.id
      state.status = 'onlyInfo'
    })
    // builder.addCase(getProfilesById.pending, (state) => {
    //   state.activeUserLoading = true
    // }) разобраться в 2-м вызове функции
    builder.addCase(getProfilesById.fulfilled, (state, action) => {
      state.activeUser = action.payload
      state.activeUserLoading = false
    })
  }
})
export const {
  openInfoPerformer,
  closeInfoPerformer,
  nextPerformer,
  backPerformer,
  openInfoPage,
  statusLoadPage
} = performerStore.actions

export const selectPerformerList = (state: RootState) => state.performer.list

export default performerStore.reducer