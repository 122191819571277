import * as React from 'react';
import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    noBorder: {
        border: "none",
    },
    w: {
        width: "185px",
        '@media screen and (max-width:1060px)': {
            position: 'absolute',
            top: 5,
            left: 'calc(50% - (185px/2))'
        }
    },
    input: {
        '&::placeholder': {
            color: "#2475FB",
            opacity: '1!important',
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "bold",
            lineHeight: "20px",
            textAlign: "left",
        }
    },
    icon: {
        color: "#2475FB",
        opacity: '1!important',
        fontSize: "15px",
        marginTop: "3px",
    },
    mobilePos: {
        paddingLeft: 0,
    }
}));

export default function Search() {
    const classes = useStyles();
    return (
        <TextField
            type="search"
            variant="outlined"
            placeholder="Быстрый поиск"
            className={classes.w}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon className={classes.icon} />
                    </InputAdornment>
                ),
                classes: { notchedOutline: classes.noBorder, input: classes.input }
            }}
        />
    );
}
