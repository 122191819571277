import React from 'react'
import { ColumnSpec } from "../../components/Table/CommonDataTable/DataTable"
import { useStyle } from "../../components/Table/useStyles"
import { TableCellProps } from '@material-ui/core';
import { CommonTableHeadCell } from './../../components/Table/CommonDataTable/CommonTableHeadCell';
import { ContainerSpan } from './../../components/Table/Common/ContainerSpan';
import { RateColorStyle } from './../../components/Table/Common/RateColorStyle';
import clsx from 'clsx';
import { TagsList } from './../../components/Table/Common/TagList';
import { ThreePointSvg } from "../../svg";
import { useSelector } from 'react-redux';
import { RootState } from "../../store";
import { NumberDigits } from '../../utils/NumberRender'
import { Performer } from '../../entities/Performer';
import { FullName } from '../../components/FullName';

const RateOprion = {
  minimum: 50,
  average: 70,
  maximum: 90,
}

export const useSpec = ({ onClickItem, onClickItemMobile }: { onClickItem?: any, onClickItemMobile?: any }) => {
  const classes = useStyle()
  const status = useSelector((state: RootState) => state.performer.status)
  const columnStatus = React.useMemo(() => {
    if (status === 'onlyTable') {
      return false
    }
    if (status === 'twoInOne') {
      return true
    }
  }, [status])
  const spec: ColumnSpec<Performer, TableCellProps, React.ComponentProps<typeof CommonTableHeadCell>>[] = [
    {
      colId: 'name',
      visible: true,
      HeadCellContentRender: () => <span>Имя</span>,
      CellContentRender: ({ item, index }) => <ContainerSpan onClick={() => onClickItem(item, index)}><FullName data={{ firstname: item.firstName, lastname: item.lastName, patronymic: item.patronymic }} /></ContainerSpan>,
      tableCellProps: { className: classes.textBold, style: { width: 415 } }
    },
    {
      colId: 'city',
      visible: true,
      HeadCellContentRender: () => <span>Город</span>,
      CellContentRender: ({ item, index }) => <ContainerSpan onClick={() => onClickItem(item, index)}>{item.city}</ContainerSpan>,
      tableCellProps: { style: { width: 300 } }
    },
    {
      colId: 'rate',
      visible: true,
      HeadCellContentRender: () => <span>Рейт.</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item, index }) => <ContainerSpan onClick={() => onClickItem(item, index)}><RateColorStyle option={RateOprion} rate={item.rating ? item.rating : 0} /></ContainerSpan>,
      tableCellProps: { className: clsx(classes.textBold, classes.textRight), style: { width: 44 } }
    },
    {
      colId: 'orders',
      visible: true,
      HeadCellContentRender: () => <span>Заказов</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item, index }) => <ContainerSpan onClick={() => onClickItem(item, index)}>{NumberDigits(item.ordersCount)}</ContainerSpan>,
      tableCellProps: { className: clsx(classes.textBold, classes.textRight), style: { width: 68 } }
    },
    {
      colId: 'tags',
      visible: !columnStatus,
      HeadCellContentRender: () => <span>Теги</span>,
      CellContentRender: ({ item, index }) => <ContainerSpan onClick={() => onClickItem(item, index)}><TagsList tags={item.tags} /></ContainerSpan>,
      tableCellProps: { className: classes.overflow }
    },
    {
      colId: 'id',
      visible: true,
      HeadCellContentRender: () => <span>#id</span>,
      tableHeadCellProps: { className: classes.textRight },
      CellContentRender: ({ item, index }) => <ContainerSpan onClick={() => onClickItem(item, index)}>{`#${item.id}`}</ContainerSpan>,
      tableCellProps: { className: clsx(classes.textLightGray, classes.textRight, !columnStatus && 'gradientBefore'), style: { width: 80 } }

    },
    {
      colId: 'button',
      visible: !columnStatus,
      HeadCellContentRender: () => <span></span>,
      tableHeadCellProps: { style: { width: 32 } },
      CellContentRender: () => <ContainerSpan ><ThreePointSvg /></ContainerSpan>,
      tableCellProps: {
        style: {
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          width: 32
        }
      }
    }
  ]
  const specMobile: ColumnSpec<Performer, TableCellProps, React.ComponentProps<typeof CommonTableHeadCell>>[] = [
    {
      colId: 'title',
      visible: true,
      HeadCellContentRender: () => <>
        <span>Имя</span>
        <span className={classes.textLightGray} style={{ marginRight: 8 }}>Город</span>
      </>,
      tableHeadCellProps: {
        style: {
          display: 'flex',
          justifyContent: 'space-between'
        }
      },
      CellContentRender: ({ item }) => <>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} style={{ display: 'flex', width: '100%' }}><FullName data={{ firstname: item.firstName, lastname: item.lastName, patronymic: '' }} /></ContainerSpan>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={clsx('elemCell gradientBefore', classes.textLight)} style={{ position: 'relative' }}>{item.city} </ContainerSpan>
      </>,
      tableCellProps: {
        className: classes.textBold, style: {
          borderRadius: '8px 8px 0px 0px',
          display: 'flex',
          justifyContent: 'space-between'
        }
      }
    },
    {
      colId: 'info',
      visible: true,
      HeadCellContentRender: () => <>
        <span>Рейтинг</span>
        <span style={{ marginLeft: 7, fontWeight: 400 }}>Заказов</span>
        <span className={classes.textLightGray} style={{ marginLeft: 'auto' }}>#id</span>
      </>,
      tableHeadCellProps: {
        style: {
          display: 'flex',
        }
      },
      CellContentRender: ({ item }) => <>
        <span style={{ maxWidth: '100px', display: 'flex' }}>
          <ContainerSpan onClick={() => onClickItemMobile(item.id)}><RateColorStyle rate={item.rating ? item.rating : 0} option={RateOprion} title={'Рейт.'} /></ContainerSpan>
          <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={classes.textColorGG} style={{ margin: '0px 12px', fontWeight: 400 }}> <span>Заказов </span>{NumberDigits(item.ordersCount)}</ContainerSpan>
        </span>
        <ContainerSpan onClick={() => onClickItemMobile(item.id)} className={clsx('elemCell gradientBefore', classes.textLightGray)} style={{ marginLeft: 'auto', position: 'relative' }}>{`#${item.id}`}</ContainerSpan>
      </>,
      tableCellProps: {
        className: classes.textBold, style: {
          borderRadius: '0px 0px 8px 8px',
          display: 'flex'
        }
      }
    }
  ]
  return { specMobile, spec }
}