import React from 'react'

export const useWindowWidth = () => {
  const WW = React.useMemo(() => {
    return window.innerWidth
  }, [window.innerWidth])

  const [windowWidth, setWindowWidth] = React.useState(WW)

  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.onload = handleResize

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize ', handleResize);
  }, [])
  return { windowWidth }
}