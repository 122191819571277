
import { makeStyles, Box } from "@material-ui/core"
import clsx from "clsx"

const usePointAndTextStyle = makeStyles({
  point: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    display: 'block',
    marginRight: 8,
    marginLeft: 8,
    background: '#EBF3F7'
  },
  done: {
    background: '#0ED609'
  },
  working: {
    background: '#2475FB'
  },
  timeout: {
    background: '#FDAF2D'
  },
  error: {
    background: '#FB2924'
  },
  default: {
    background: '#EBF3F7'
  }
})

interface PointAndTextProps {
  value?: string,
  view: boolean,
  page?: string
}

export const PointAndText = ({ value, view, page }: PointAndTextProps) => {
  const classes = usePointAndTextStyle()

  const classPoint = clsx(classes.point, value === 'active' && classes.working, value === 'completed' && classes.done, value === 'timeout' && classes.timeout, value === 'canceled' && classes.error, value === 'default' && classes.default)

  const lableStatusText = (value?: string) => {
    if (value === 'active') {
      return 'Выполняется'
    }
    if (value === 'completed') {
      return 'Выполнена'
    }
    if (value === 'timeout') {
      return 'В ожидании'
    }
    if (value === 'canceled') {
      return 'Отменена'
    }
    if (value === 'default') {
      return 'Статус'
    }
    return ''
  }
  const lableStatusTextNotif = (value?: string) => {
    switch (value) {
      case 'done': return 'Отправлено'
      case 'timeout': return 'Ожидание'
      case 'error': return 'Отменена'

      default: return ''
    }
  }
  if (page === 'Notif') {
    return (
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <span className={classPoint}></span>
        {
          view && lableStatusTextNotif(value)
        }
      </Box >
    )
  }
  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <span className={classPoint}></span>
      {
        view && lableStatusText(value)
      }
    </Box >
  )
}