import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { Cities, City, CityInfoPerformers, CityInfoServices } from '../../entities/City'
import { addNextPage, getCityById, loadCities, PerformersForCity, ServicesForCity } from './thunks'

interface CityStore {
  status: string,
  view: 'list' | 'map'
  count: number | null,
  list: Cities[] | any,
  listLoading: true | false
  activeItem?: City | null,
  activeItemId?: number,
  activeItemIndex?: number,
  performersForCity: {
    loading: boolean,
    state?: CityInfoPerformers
  },
  servicesForCity: {
    loading: boolean,
    state?: CityInfoServices
  },
  pagination?: {
    id: number,
    title: string
  }[]
}
const initialState: CityStore = {
  status: 'onlyTable',
  view: 'list',
  count: 0,
  list: [],
  activeItem: null,
  activeItemId: 0,
  activeItemIndex: 0,
  listLoading: true,
  performersForCity: {
    loading: true
  },
  servicesForCity: {
    loading: true
  }
}

export const cityStore = createSlice({
  name: "cityStore",
  initialState,
  reducers: {
    openInfoTable: (state, action: PayloadAction<{ item: City, index: number }>) => {
      state.activeItem = action.payload.item
      state.activeItemId = action.payload.item.id
      state.activeItemIndex = action.payload.index
      state.status = 'twoInOne'
    },
    closeInfoTable: state => {
      state.status = 'onlyTable'
      state.activeItem = null
      state.activeItemId = 0
      state.activeItemIndex = 0
    },
    nextItem: state => {
      if (state.activeItemIndex !== undefined) {
        if (state.list?.length !== 0) {
          state.activeItem = state.list[0].results[state.activeItemIndex + 1]
          state.activeItemId = state.list[0].results[state.activeItemIndex + 1].id
          state.activeItemIndex = state.activeItemIndex + 1
        }
      }
    },
    backItem: state => {
      if (state.activeItemIndex !== undefined && (state.activeItemIndex - 1) >= 0) {
        if (state.list?.length !== 0) {
          state.activeItem = state.list[0].results[state.activeItemIndex - 1]
          state.activeItemId = state.list[0].results[state.activeItemIndex - 1].id
          state.activeItemIndex = state.activeItemIndex - 1
        }
      }
    },
    openInfoPage: state => {
      state.status = 'onlyInfo'
    },
    statusLoadPage: (state, action: PayloadAction<{ status: string }>) => {
      state.status = action.payload.status
    },
    setView: (state, action: PayloadAction<{ view: 'list' | 'map' }>) => {
      state.view = action.payload.view
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadCities.pending, (state) => {
      state.listLoading = true
    })
    builder.addCase(loadCities.fulfilled, (state, action) => {
      state.count = action.payload?.count ? action.payload?.count : 0
      state.list?.push(action.payload)
      state.listLoading = false

      const pagin = []
      const count = Math.trunc((action.payload?.count ? action.payload?.count : 0) / 100) + ((action.payload?.count ? action.payload?.count : 0) % 100 > 0 ? 1 : 0)
      for (let index = 0; index < count; index++) {
        pagin.push({ id: index, title: `Стр. ${index + 1}` })
      }
      state.pagination = pagin

    })

    builder.addCase(addNextPage.pending, (state) => {
      state.listLoading = true
    })
    builder.addCase(addNextPage.fulfilled, (state, action) => {
      if (state.list[state.list.length - 1].next !== action.payload?.next) {
        state.list?.push(action.payload)
      }
      state.listLoading = false
    })

    builder.addCase(PerformersForCity.pending, (state) => {
      state.performersForCity.loading = true
    })
    builder.addCase(PerformersForCity.fulfilled, (state, action) => {
      state.performersForCity.loading = false
      state.performersForCity.state = action.payload
    })

    builder.addCase(ServicesForCity.pending, (state) => {
      state.servicesForCity.loading = true
    })
    builder.addCase(ServicesForCity.fulfilled, (state, action) => {
      state.servicesForCity.loading = false
      state.servicesForCity.state = action.payload
    })

    builder.addCase(getCityById.fulfilled, (state, action) => {
      state.activeItem = action.payload
      state.activeItemId = action.payload.id
      state.status = 'onlyInfo'
    })
  }
})

export const { openInfoTable,
  closeInfoTable,
  openInfoPage,
  statusLoadPage,
  nextItem,
  backItem,
  setView } = cityStore.actions

export const selectCities = (state: RootState) => state.city.list

export default cityStore.reducer