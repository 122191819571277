import { TableCell, withStyles } from "@material-ui/core";

export const CommonTableHeadCell = withStyles({
  root: {
    padding: '0px',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#74737B',
    border: 'none',
    position: 'relative',
  }
})(TableCell);
