import { createAsyncThunk } from "@reduxjs/toolkit"
import { Services } from "../../entities/Service"

export const fetchServiceTable = createAsyncThunk(
  'service/fetchService',
  async () => {
    const serviceListRes = await fetch('https://test.b2c.by/api/v1/dashboard/services/')

    if (serviceListRes.status !== 200) {
      return null
    }
    return (await serviceListRes.json()) as Promise<Services>
  }
)