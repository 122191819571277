import React from "react"
import {
  makeStyles
} from "@material-ui/core"
import { CommonTable } from "../../components/Table/CommonDataTable/CommonTable"
import { DataTable } from "../../components/Table/CommonDataTable/DataTable"
import { CommonTableRow, CommonTableRowProps, CammonTableRowMobile } from "../../components/Table/CommonDataTable/CommonTableRow"
import { CommonTableCell, CommonTableCellProps } from "../../components/Table/CommonDataTable/CommonTableCell"
import { CommonTableHeadRow, CommonTableHeadRowMobile } from "../../components/Table/CommonDataTable/CommonTableHeadRow"
import { CommonTableHeadCell } from "../../components/Table/CommonDataTable/CommonTableHeadCell"
import { CommonTableHead } from "../../components/Table/CommonDataTable/CommonTableHead"
import { useSelector } from 'react-redux'
import { RootState } from "../../store"
import { setNotificationsStatus } from "../../store/table/tableStore"
import { useAppDispatch } from "../../hooks/useAppDispatch"

import testdata from './data.json'
import { useSpec } from "./spec"

export interface Notif {
  title: string,
  type: string,
  receiver: {
    firstname: string,
    lastname: string,
    patronymic: string,
  }
  phone: string,
  role: string,
  status: string,
  date: string,
  id: string
}

const TableMobileStyle = makeStyles({
  mediaCities: {
    '@media screen and (max-width:1200px)': {
      '& tr': {
        display: 'grid',
        gridTemplateRows: "repeat(3, 30px)",
      }
    }
  }
})

export const NotifTable = ({ windowWidth }: { windowWidth: number }) => {
  const dispatch = useAppDispatch()
  const status = useSelector((state: RootState) => state.table.Notifications.status)
  const columnStatus = React.useMemo(() => {
    if (status === 'onlyTable') {
      return false
    }
    if (status === 'twoInOne') {
      return true
    }
  }, [status])

  const onClickItem = (item: string) => {
    dispatch(setNotificationsStatus({ status: 'twoInOne', id: item }))
  }
  const onClickItemMobile = (activeItemId: string) => {
    alert(`page "events/${activeItemId}" in developmen`)
  }
  const { spec, specMobile } = useSpec({ onClickItem, onClickItemMobile })

  const checkWidth = React.useMemo(() => {
    if (windowWidth <= 1200) {
      return specMobile
    }
    if (windowWidth > 1200) {
      return spec
    }
    return spec
  }, [windowWidth, columnStatus, spec, specMobile])

  const Row = React.useMemo(() => {
    if (windowWidth <= 1200) {
      return CammonTableRowMobile
    }
    if (windowWidth > 1200) {
      return CommonTableRow
    }
    return CommonTableRow
  }, [windowWidth])

  const HeadRow = React.useMemo(() => {
    if (windowWidth <= 1200) {
      return CommonTableHeadRowMobile
    }
    if (windowWidth > 1200) {
      return CommonTableHeadRow
    }
    return CommonTableHeadRow
  }, [windowWidth])


  return (
    <DataTable<
      Notif,
      React.ComponentProps<typeof CommonTable>,
      CommonTableRowProps<Notif>,
      CommonTableCellProps<Notif>,
      React.ComponentProps<typeof CommonTableHead>,
      React.ComponentProps<typeof CommonTableHeadRow>,
      React.ComponentProps<typeof CommonTableHeadCell>
    >
      data={testdata as Notif[]}
      Table={CommonTable}
      tableProps={{ className: TableMobileStyle().mediaCities }}
      TableRow={Row}
      TableCell={CommonTableCell}
      TableHead={CommonTableHead}
      TableHeadRow={HeadRow}
      TableHeadCell={CommonTableHeadCell}
      spec={checkWidth}
      itemKey={(city: Notif) => city.id}
    />
  )
}